import * as React from 'react'
import styled from 'styled-components'

/* Props Interface */
interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  size: string // small, medium, large
  isVisible: boolean
  children?: any
  outterClick: () => void
}

interface IOverlayProps {
  isVisible: boolean
}

/* Styled Component */
const StyledModal = styled.div<IProps>`
  width: ${(props) => SizeAsNumber(props.size)};
  background-color: #fff;
  font-family: ${(props) => props.theme.fontFamily};

  position: fixed;
  top: 0;
  right: 0;
  // bottom: 0;
  left: 0;
  margin: auto;
  max-height: 250px;

  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  z-index: 200;
`

const Overlay = styled.div<IOverlayProps>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  // bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 150;
  cursor: pointer;
`

const SizeAsNumber = (size: string): string => {
  switch (size) {
    case 'small':
      return '40%'
    case 'medium':
      return '60%'
    case 'large':
      return '75%'
    default:
      return '0'
  }
}

export class Modal extends React.Component<IProps, object> {
  onOutterClick = () => this.props.outterClick()

  /**
   * Modal.
   */
  /** @component */
  render() {
    return (
      <>
        <Overlay onClick={this.onOutterClick} isVisible={this.props.isVisible} />
        <StyledModal {...this.props}>{this.props.children}</StyledModal>
      </>
    )
  }
}
