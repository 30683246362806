/* eslint-disable no-undef */
import styled from 'styled-components'
import { media } from '../../Themes'

interface IProps extends React.HTMLAttributes<any> {
  phone?: string | number
  tablet?: string | number
  desktop?: string | number
}

export const withGrid = (component: React.ComponentType<React.HTMLAttributes<any>>) =>
  styled(component)<IProps>`
    display: grid;
    grid-gap: 15px;

    ${(props) => props.phone && media.phone(gridTemplateColumns(props.phone))}

    ${(props) => props.tablet && media.tablet(gridTemplateColumns(props.tablet))}

    ${(props) => props.desktop && media.desktop(gridTemplateColumns(props.desktop))}
  `

const gridTemplateColumns = (args: string | number): string => {
  switch (typeof args) {
    case 'string':
      return `grid-template-columns: ${args}`
    case 'number':
      return `grid-template-columns: repeat(${args}, 1fr);`
  }

  return ''
}
