import { Formulario } from "../domain/Shared"

export const faseAtual = (form: Formulario) => {
    let dataAtual = new Date().getTime()
    let inicioRecurso = new Date(form?.dataInicioRecurso).getTime()
    let fimRecurso = new Date(form?.dataFimRecurso).getTime()
    if(dataAtual > inicioRecurso && dataAtual < fimRecurso){
      return 'recurso'
    }
    if(form.dataInicioInscricao){
      let inicioInscricao = new Date(form.dataInicioInscricao).getTime()
      let fimInscricao = new Date(form.dataFimInscricao).getTime()
      if(dataAtual > inicioInscricao && dataAtual < fimInscricao){
        return 'inscricao'
      }
    }
    if(form.dataInicioComplementacao){
      let inicioComplementacao = new Date(form?.dataInicioComplementacao).getTime()
      let fimComplementacao = new Date(form?.dataFimComplementacao!).getTime()
      if(dataAtual > inicioComplementacao && dataAtual < fimComplementacao){
        return 'complementacao'
      }
    }
    return '-'
  }