import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import App from './App';
import './index.css'
import { SigninCallback } from './auth/SigninCallback';
import { SilentRenew } from './auth/SilentRenew';
import { Store } from './store';
import { ThemeProvider } from 'styled-components'
import { theme } from './Themes'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={Store}>
      <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path='/silent-renew' element={<SilentRenew/>} />
          <Route path='/signin-callback' element={<SigninCallback/>} />
          <Route path="/*" element={<App/>} />
        </Routes>
      </BrowserRouter>
      </ThemeProvider>
    </Provider>
);
