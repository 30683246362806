import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router'
import { Dispatch } from 'redux'
import { AuthActions, RootState } from '../store'
import { CurrentUserModel } from '../domain/Auth'

interface IUsePageAuthorization {
  roles: string[]
  pathName: string
  goTo: (path: string, state?: any) => void
  isLoggedIn: boolean
}

const usePageAuthorization = ({ roles, pathName, goTo, isLoggedIn }: IUsePageAuthorization) => {
  React.useEffect(() => {
    if (roles.length > 0) {
      let isValidUrl = false
      roles.forEach((element: string) => {
        if (pathName.startsWith('/' + element)) isValidUrl = true
      })

      if (!isValidUrl) {
        if (pathName === '/') {
          goTo('/' + roles[0])
        } else {
          goTo('/error?id=1')
        }
      }
    }
  }, [roles, pathName, goTo, isLoggedIn])
}

interface IUseKeepTokenAlive {
  isTokenExpired: boolean
  isLoggedIn: boolean
  dispatch: Dispatch<any>
}

const useKeepTokenAlive = ({ isTokenExpired, isLoggedIn, dispatch }: IUseKeepTokenAlive) => {
  React.useEffect(() => {
    if (!isLoggedIn) {
      dispatch(AuthActions.loginAction())
    } else if (isTokenExpired) dispatch(AuthActions.tokenRenewAction())
  }, [isTokenExpired, isLoggedIn, dispatch])
}

interface IUseUpdateRoles {
  setRoles: React.Dispatch<React.SetStateAction<string[]>>
  currentUser?: CurrentUserModel
}

const useUpdateRoles = ({ setRoles, currentUser }: IUseUpdateRoles) => {
  React.useEffect(() => {
    if (currentUser) setRoles(currentUser!.tipoUsuario || [])
  }, [setRoles, currentUser])
}

export const useAuth = () => {
  const user = useSelector((props: RootState) => props.Auth.user)
  const currentUser = useSelector((props: RootState) => props.Auth.currentUser)
  const dispatch = useDispatch()
  //const history = useHistory()

  const isLoggedIn = Boolean(user)
  const isTokenExpired = Boolean(isLoggedIn && user?.expired)
  const [roles, setRoles] = React.useState<string[]>([])
  //const pathName = history.location.pathname
  const pathName = useLocation().pathname
  //const goTo = history.push
  const goTo = useNavigate()

  useKeepTokenAlive({ isTokenExpired, isLoggedIn, dispatch })
  useUpdateRoles({ setRoles, currentUser })
  usePageAuthorization({ pathName, roles, goTo, isLoggedIn })

  return { isLoggedIn, roles }
}
