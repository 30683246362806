import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.HTMLAttributes<any> {
  color?: string
  onClick?: any
}

const StyledContainer = styled.div<{
  color: string
}>`
  background-color: ${(props) => props.theme[props.color]};
  padding: 6px 10px;
  color: ${(props) => props.theme.lightTextColor};
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.defaultFontSize};
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  margin-right: 8px;
  margin-left: -4px;
  margin-bottom: 6px;
  float: right;
  white-space: pre;
  height: fit-content;
  transition: background-color 150ms ease-out, box-shadow 150ms ease-out;
  cursor: default;
  &:enabled {
    background-color: ${(props) => props.theme.greenColor};
    color: ${(props) => props.theme.whiteTextColor};
    box-shadow: none;
  }
`

export const Tag: React.FC<IProps> = (props: IProps) => (
  <StyledContainer color={props.color || 'primaryColor'} onClick={props.onClick}>{props.children}</StyledContainer>
)
