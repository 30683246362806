import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Documento, MembroFamiliar, Pensao } from '../../domain/Shared'
import { RootState } from '../../store'
import { AlunoActions } from '../../store/Aluno' 

export const useAluno = () => {
  const dispatch = useDispatch()
  const aluno = useSelector((state: RootState) => state.Aluno.aluno)
  const gabarito = useSelector((state: RootState) => state.Aluno.gabarito)
  const analiseGabarito = useSelector((state: RootState) => state.Aluno.analiseGabarito)
  const alunoApiState = useSelector((state: RootState) => state.Aluno.apiStates.alunoAction)
  const respostas = useSelector((state: RootState) => state.Aluno.respostas)
  const respostasApiState = useSelector((state: RootState) => state.Aluno.apiStates.respostasAction)
  const membros = useSelector((state: RootState) => state.Aluno.membros)
  const pensao = useSelector((state: RootState) => state.Aluno.pensao)
  const endereco = useSelector((state: RootState) => state.Aluno.endereco)
  const novoEndereco = useSelector((state: RootState) => state.Aluno.newEndereco)
  const arquivo = useSelector((state: RootState) => state.Aluno.arquivo)
  const tipoRelacionamento = useSelector((state: RootState) => state.Aluno.tipoRelacionamento)
  const tipoRenda = useSelector((state: RootState) => state.Aluno.tipoRenda)
  const tipoDocumento = useSelector((state: RootState) => state.Aluno.tipoDocumento)
  const tipoComprovante = useSelector((state: RootState) => state.Aluno.tipoComprovante)
  const tipoComprovanteEndereco = useSelector((state: RootState) => state.Aluno.tipoComprovanteEndereco)
  const membrosApiState = useSelector((state: RootState) => state.Aluno.apiStates.membrosAction)
  const cadastrarMembroApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarMembroAction)
  const cadastrarDocumentoApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarDocumentoAction)
  const cadastrarRendaApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarRendaAction)
  const cadastrarRespostaApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarRespostasAction)
  const excluirMembroApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteMembroAction)
  const excluirDocumentoApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteDocumentoAction)
  const excluirRendaApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteRendaAction)
  const alterarTelefoneApiState = useSelector((state: RootState) => state.Aluno.apiStates.alterarTelefoneAction)
  const alterarEnderecoApiState = useSelector((state: RootState) => state.Aluno.apiStates.alterarEnderecoAction)
  const cadastrarPensaoApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarPensaoAction)
  const excluirComprovanteEnderecoApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteComprovanteEnderecoAction)
  const excluirComprovantePensaoApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteComprovantePensaoAction)
  const excluirComprovanteRespostaApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteComprovanteRespostaAction)
  const excluirPensaoApiState = useSelector((state: RootState) => state.Aluno.apiStates.deletePensaoAction)
  const excluirAnexoIRApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteAnexoIRAction)
  const cadastrarIRApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarIRAction)
  const excluirAnexoCTPSApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteAnexoCTPSAction)
  const cadastrarCTPSApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarCTPSAction)
  const excluirAnexoPISApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteAnexoPISAction)
  const cadastrarPISApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarPISAction)
  const excluirAnexoExtratoApiState = useSelector((state: RootState) => state.Aluno.apiStates.deleteAnexoExtratoAction)
  const cadastrarExtratoApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarExtratoAction)
  const cadastrarObservacaoApiState = useSelector((state: RootState) => state.Aluno.apiStates.cadastrarObservacaoAction)
  const finalizarProcessoApiState = useSelector((state: RootState) => state.Aluno.apiStates.finalizarProcessoAction)
  const finalizarComplementacaoApiState = useSelector((state: RootState) => state.Aluno.apiStates.finalizarComplementacaoAction)

  const alterarEndereco = (endereco: any | null | undefined) => {
    dispatch(AlunoActions.alterarEnderecoAction(endereco))
  }

  const finalizarProcesso = (finalizado: boolean) => {
    dispatch(AlunoActions.finalizarProcessoAction(finalizado))
  }

  const finalizarComplementacao = (finalizado: boolean) => {
    dispatch(AlunoActions.finalizarComplementacaoAction(finalizado))
  }

  const retornarArquivo = (
    documentoId: string| undefined, 
    tipoDocumentoId: string | undefined,
    anexoId?: string | undefined,
    ) => {
    dispatch(AlunoActions.retornarArquivoAction(documentoId, tipoDocumentoId,anexoId))
  }

  const aceitarTermo = () => {
    dispatch(AlunoActions.aceitarTermoAction())
  }

  const consultarCep = (cep: string) => {
    dispatch(AlunoActions.consultaCepAction(cep))
  }

  const cadastrarMembro = (membro: MembroFamiliar | undefined) => {
    dispatch(AlunoActions.cadastrarMembroAction(membro))
  }

  const cadastrarDocumento = (documento: Documento) => {
    dispatch(AlunoActions.cadastrarDocumentoAction(documento))
  }

  const cadastrarResposta = (resposta: any) => {
    dispatch(AlunoActions.cadastrarRespostasAction(resposta))
  }

  const cadastrarPensao = (pensao: Pensao | undefined) => {
    dispatch(AlunoActions.cadastrarPensaoAction(pensao))
  }

  const cadastrarObservacao = (observacao: string) => {
    dispatch(AlunoActions.cadastrarObservacaoAction(observacao))
  }

  const cadastrarIR = (ir: any) => {
    dispatch(AlunoActions.cadastrarIRAction(ir))
  }

  const cadastrarCTPS = (ctps: any) => {
    dispatch(AlunoActions.cadastrarCTPSAction(ctps))
  }

  const cadastrarPIS = (pis: any) => {
    dispatch(AlunoActions.cadastrarPISAction(pis))
  }

  const cadastrarExtrato = (extrato: any) => {
    dispatch(AlunoActions.cadastrarExtratoAction(extrato))
  }

  const cadastrarRenda = (renda: any) => {
    dispatch(AlunoActions.cadastrarRendaAction(renda))
  }

  const updateMembros = () => {
    dispatch(AlunoActions.membrosAction())
  }

  const updateEndereco = () => {
    dispatch(AlunoActions.enderecoAction())
  }
  
  const updateRespostas = () => {
    dispatch(AlunoActions.respostasAction())
  }

  const excluirMembro = (id: string | undefined) => {
    dispatch(AlunoActions.deleteMembroAction(id))
  }

  const excluirDocumento = (id: string | undefined) => {
    dispatch(AlunoActions.deleteDocumentoAction(id))
  }

  const excluirRenda = (id: string) => {
    dispatch(AlunoActions.deleteRendaAction(id))
  }

  const excluirAnexoIR = (id: string | undefined) => {
    dispatch(AlunoActions.deleteAnexoIRAction(id))
  }

  const excluirAnexoCTPS = (id: string | undefined) => {
    dispatch(AlunoActions.deleteAnexoCTPSAction(id))
  }

  const excluirAnexoPIS = (id: string | undefined) => {
    dispatch(AlunoActions.deleteAnexoPISAction(id))
  }

  const excluirAnexoExtrato = (id: string | undefined) => {
    dispatch(AlunoActions.deleteAnexoExtratoAction(id))
  }

  const excluirComprovanteEndereco = (id: string | undefined) => {
    dispatch(AlunoActions.deleteComprovanteEnderecoAction(id))
  }

  const excluirComprovanteResposta = (id: string | undefined) => {
    dispatch(AlunoActions.deleteComprovanteRespostaAction(id))
  }

  const excluirComprovantePensao = (id: string | undefined) => {
    dispatch(AlunoActions.deleteComprovantePensaoAction(id))
  }

  const excluirPensao = (id: string | undefined) => {
    dispatch(AlunoActions.deletePensaoAction(id))
  }

  const alterarTelefone = (telefone: string) => {
    dispatch(AlunoActions.alterarTelefoneAction(telefone))
  }

  const updatePensao = () => {
    dispatch(AlunoActions.pensaoAction())
  }

  const updateGabarito = () => {
    dispatch(AlunoActions.gabaritoAction())
  }

  React.useEffect(() => {
    dispatch(AlunoActions.alunoAction())
    dispatch(AlunoActions.respostasAction())
    dispatch(AlunoActions.membrosAction())
    dispatch(AlunoActions.enderecoAction())
    dispatch(AlunoActions.pensaoAction())
    dispatch(AlunoActions.gabaritoAction())
    dispatch(AlunoActions.analiseGabaritoAction())
    dispatch(AlunoActions.tiposAction('tiporelacao'))
    dispatch(AlunoActions.tiposAction('tipodocumento'))
    dispatch(AlunoActions.tiposAction('tiporenda'))
    dispatch(AlunoActions.tiposAction('TipoComprovanteRenda'))
    dispatch(AlunoActions.tiposAction('tipocomprovanteendereco'))
  }, [dispatch])


  return {
    aluno,
    gabarito,
    analiseGabarito,
    pensao,
    respostas,
    respostasApiState,
    membros,
    endereco,
    novoEndereco,
    tipoDocumento,
    tipoComprovante,
    retornarArquivo,
    arquivo,
    tipoRelacionamento,
    tipoRenda,
    tipoComprovanteEndereco,
    alunoApiState,
    membrosApiState,
    cadastrarMembroApiState,
    cadastrarDocumentoApiState,
    cadastrarRendaApiState,
    cadastrarRespostaApiState,
    excluirMembroApiState,
    excluirDocumentoApiState,
    excluirRendaApiState,
    excluirComprovanteRespostaApiState,
    alterarTelefoneApiState,
    alterarEnderecoApiState,
    cadastrarPensaoApiState,
    excluirComprovanteEnderecoApiState,
    excluirComprovantePensaoApiState,
    excluirPensaoApiState,
    cadastrarIRApiState,
    excluirAnexoIRApiState,
    excluirAnexoCTPSApiState,
    cadastrarCTPSApiState,
    cadastrarPISApiState,
    cadastrarExtratoApiState,
    excluirAnexoPISApiState,
    excluirAnexoExtratoApiState,
    cadastrarObservacaoApiState,
    finalizarProcessoApiState,
    finalizarComplementacaoApiState,
    finalizarComplementacao,
    finalizarProcesso,
    updateGabarito,
    cadastrarObservacao,
    excluirAnexoPIS,
    excluirAnexoExtrato,
    cadastrarPIS,
    cadastrarExtrato,
    excluirAnexoCTPS,
    cadastrarCTPS,
    excluirAnexoIR,
    excluirComprovanteResposta,
    excluirComprovanteEndereco,
    updatePensao,
    updateRespostas,
    updateMembros,
    cadastrarIR,
    cadastrarRenda,
    cadastrarDocumento,
    cadastrarPensao,
    cadastrarMembro,
    excluirMembro,
    excluirDocumento,
    excluirRenda,
    cadastrarResposta,
    alterarTelefone,
    consultarCep,
    aceitarTermo,
    alterarEndereco,
    updateEndereco,
    excluirComprovantePensao,
    excluirPensao,
  }
}