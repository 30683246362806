import * as React from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'

interface IState {
  isFocus: boolean
  hasValue: boolean
}

interface IProps extends React.InputHTMLAttributes<any> {
  color?: string
  innerRef?: any
  mask?: string
}

const StyledInputContainer = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
`

const StyledInput = styled.input<{
  isFocus: boolean
  themeColor: string
}>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;

  padding: 12px 24px;
  width: 100%;
  outline: 0;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme.greyColor};
`
const isEmpty = (value: any) => {
  return value !== '' || value.type === 'date' || value.type === 'hora'
}

/**
 * Input
 */
/** @component */
export class InputSearch extends React.Component<Partial<IProps>, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      hasValue: !isEmpty(this.props.value),
      isFocus: false,
    }
  }

  focus = () => {
    if (this.props.type !== 'date') this.setState({ isFocus: true })
  }

  blur = (e: any) => {
    const hasValue = e.target.value
    if (this.props.type !== 'date') this.setState({ isFocus: false, hasValue })
  }
  static getDerivedStateFromProps = (props: IProps) => {
    return props.value !== '' || props.type === 'date'
  }

  render() {
    const { style, color, id, mask, ...props } = this.props

    return (
      <StyledInputContainer style={style} {...props}>
        {mask ? (
          <InputMask mask={mask} onFocus={this.focus} onBlur={this.blur}>
            {/* {(inputProps: any) => (
              <StyledInput
                {...inputProps}
                id={id}
                themeColor={color!}
                onFocus={this.focus}
                onBlur={this.blur}
                isFocus={this.state.isFocus}
              />
            )} */}
          </InputMask>
        ) : (
          <StyledInput
            {...props}
            id={id}
            themeColor={color!}
            onFocus={this.focus}
            onBlur={this.blur}
            isFocus={this.state.isFocus}
          />
        )}{' '}
      </StyledInputContainer>
    )
  }

  static defaultProps = {
    color: 'secondaryColor',
    hasError: false,
  }
}
