import * as React from 'react'
import { Card, CardTitle, CardContent } from '../../Components'
import styled from 'styled-components'

const StyledCard = styled(Card)`
  padding: 23px;
  font-size: 20px;
`
const StyledCardTitle = styled(CardTitle)`
  font-size: 22px;
`

const NotFoundPage: React.FC = () => (
  <StyledCard>
    <StyledCardTitle>Página não encontrada</StyledCardTitle>
    <CardContent>A página que você está procurando não existe ou você não tem permissão para acessá-la.</CardContent>
    <CardContent>Procure a comissão do Promais para mais informações.</CardContent>
  </StyledCard>
)

export default NotFoundPage
