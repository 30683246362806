import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit'
import { AlunoReducer } from './Aluno'
import { AuthReducer } from './Auth'
import { ComissaoReducer } from './Comissao'
import { SharedReducer } from './Shared'

export const Store = configureStore({
  reducer: {
    Auth: AuthReducer,
    Aluno: AlunoReducer,
    Comissao: ComissaoReducer,
    Shared: SharedReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),  
})

export type RootState = ReturnType<typeof Store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
