import React from 'react'
import styled from 'styled-components'

interface IProps {
  color: string
  children?: any
  isFlat: boolean
}

const Container = styled.div<IProps>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  position: relative;
  top: 0;
  padding: 0 16px 0 16px;
  box-sizing: border-box;

  display: flex;
  align-items: center;

  width: 100%;
  height: 64px;
  color: white;

  ${(props) => getBoxShadow(props.isFlat)}
  background: ${(props) => (props.color ? props.theme[props.color] : props.theme.primaryColor)}
`

export const Navbar = ({ children, ...props }: IProps) => {
  const defaultProps = { ...props }
  defaultProps.isFlat = defaultProps.isFlat === null ? false : defaultProps.isFlat
  return (
    <header>
      <Container {...defaultProps}>{children}</Container>
    </header>
  )
}

const getBoxShadow = (isFlat: boolean): string => {
  if (isFlat) return ''
  else
    return `box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12),
        0 3px 1px -2px rgba(0, 0, 0, 0.2);`
}
