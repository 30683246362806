import styled from 'styled-components'
import { withGrid } from './WithGrid'

const StyledContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 15px;
  max-width: 80em;
  margin: 0 auto;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
`

export const Container = withGrid(StyledContainer)
