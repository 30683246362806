import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.InputHTMLAttributes<any> {
  color?: string
  label: string
  hasError?: boolean
  errorMessage?: string | null | undefined | {}
}

const Container = styled.label`
  position: relative;
  padding-left: 26px;

  display: inline-flex;
  flex-direction: column;

  cursor: pointer;
  margin: 5px 0px 5px 0px;

  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.greyColor};
  background-color: white;

  box-sizing: border-box;

  transition: background-color 150ms ease-out;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }

  ${Container} &:after {
    box-sizing: border-box;

    left: 5px;
    top: 2.5px;

    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

const InputCheckbox = styled.input`
  position: absolute;
  display: none;
  opacity: 0;

  &:checked + ${Checkmark} {
    background: ${(props) => props.theme.secondaryColor};
    border-color: ${(props) => props.theme.secondaryColor};
  }

  &:checked + ${Checkmark}:after {
    display: block;
  }
`

const ErrorLabel = styled.label`
  font-size: calc(${(props) => props.theme.defaultFontSize} * 0.85);
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.errorColor};

  position: relative;
`

export const Checkbox = (props: IProps) => {
  const ErrorDOM: any = props.hasError ? props.errorMessage : null
  return (
    <Container {...props}>
      {props.label}
      <InputCheckbox type="checkbox" {...props} />
      <Checkmark />
      <ErrorLabel>{ErrorDOM}</ErrorLabel>
    </Container>
  )
}
