import * as React from "react";
import { Routes, Route } from "react-router-dom";

const ComissaoPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ComissaoPagina" */ "./ComissaoPagina"
    )
)

const ProcessoDetalhesPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "ProcessoDetalhesPagina" */ "./ProcessoDetalhesPagina"
    )
)

export const ComissaoIndexPage: React.FC = () => {
  return (
    <React.Suspense
      fallback={
        <p>
          <strong>Carregando...</strong>
        </p>
      }
    >
    <Routes>
      <Route path={`/`} element={<ComissaoPagina />} />
      <Route path={"/processo-detalhes/:id/:analiseComissaoId"} element={<ProcessoDetalhesPagina />} />
    </Routes>
    </React.Suspense>
  );
};
