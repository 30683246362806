import * as PropTypes from 'prop-types'
import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  name: string
  selectedValue: string | undefined
  onChange?: any
  children: any
}

const StyledDiv = styled.div`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};
`

export class RadioGroup extends React.Component<IProps, object> {
  static childContextTypes = {
    radioGroup: PropTypes.object,
  }

  getChildContext = () => {
    const { name, selectedValue, onChange } = this.props
    return {
      radioGroup: {
        name,
        selectedValue,
        onChange,
      },
    }
  }

  render() {
    const { ...rest } = this.props
    return (
      <StyledDiv role="radiogroup" {...rest}>
        {this.props.children}
      </StyledDiv>
    )
  }
}
