import styled from "styled-components";

export const DragContainer = styled.div`
  padding: 10px 10px;
  border: 2px dotted #aaa;
  border-radius: 10px;
  background-color: #eee;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px
`;