import * as React from 'react'
//import { RouteComponentProps } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { AuthActions, RootState } from '../store/'
import {useNavigate} from 'react-router-dom'

//export const SigninCallback = ({ history }: RouteComponentProps) => {
export const SigninCallback = () => {
  const dispatch = useDispatch()
  const validUser = Boolean(useSelector((state: RootState) => state.Auth.user))
  //const goTo = history.push
  const goTo = useNavigate()

  React.useEffect(() => {
    dispatch(AuthActions.signinRedirectCallbackAction())
  }, [dispatch])

  React.useEffect(() => {
    if (validUser) goTo('/')
  }, [validUser, goTo])

  return <>Logando</>
}
