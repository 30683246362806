import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.HTMLAttributes<any> {
  icon: string
}

const StyledIcon = styled.i`
  display: inline-block;
  margin-right: 24px;
  color: ${(props) => props.theme.greyTextColor};
`

export const ListItemIcon = ({ icon, ...props }: IProps) => (
  <StyledIcon className="material-icons" {...props}>
    {icon}
  </StyledIcon>
)
