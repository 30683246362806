import * as React from 'react'
import styled from 'styled-components'

interface TabItemProps {
  active: boolean
  titulo: string
  subtitulo?: string
  click: (e: string) => {}
  badge?: string
}

const StyledTabItem = styled.div<{
  active?: boolean
}>`
  height: 50px;
  padding: 5px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => {
    if (props.active) {
      return `color: ${props.theme.primaryColor};
                    border-bottom: 3px solid ${props.theme.primaryColor};
                    font-weight: 500;`
    } else return ''
  }}
`

export class TabItem extends React.Component<TabItemProps> {
  render() {
    return (
      <StyledTabItem
        onClick={() => {
          this.props.click(this.props.titulo + (this.props.subtitulo || ''))
        }}
        {...this.props}
      >
        {this.props.children}
      </StyledTabItem>
    )
  }
}
