import * as React from 'react'
import styled from 'styled-components'

const Title = styled.h1`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: calc(${(props) => props.theme.defaultFontSize} * 1.5);
  font-weight: 400;

  color: ${(props) => props.theme.lightTextColor};
`

export const PageTitle: React.FC = () => {
  const pathname = window.location.pathname.split('/')

  switch (pathname[1]) {
    case 'comissao':
      return <Title>Promais</Title>

    case 'aluno':
      return <Title>Promais</Title>

    default:
      return <Title>Promais</Title>
  }
}
