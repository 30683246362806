import * as React from 'react'
import styled from 'styled-components'

/* Props Interface */
interface IProps extends React.HTMLAttributes<any> {
  isFlat?: boolean
}

const StyledCard = styled.div<IProps>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.defaultFontSize};
  font-weight: 400;

  background-color: white;

  border: 1px solid ${(props) => props.theme.greyColor};
  border-radius: 2px;

  ${(props) => getBoxShadow(props.isFlat)};
`

export const Card = React.forwardRef<any, IProps>(
  ({ isFlat = true, children, ...props }: IProps, ref: React.Ref<any>) => {
    return (
      <StyledCard ref={ref} {...props} isFlat={isFlat}>
        {children}
      </StyledCard>
    )
  }
)

const getBoxShadow = (isFlat?: boolean): string => {
  return !isFlat
    ? `box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                 0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                        0px 2px 1px -1px rgba(0, 0, 0, 0.12);

    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                     0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                     0px 2px 1px -1px rgba(0, 0, 0, 0.12);`
    : ''
}
