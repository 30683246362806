import styled from 'styled-components'
import { Button } from './Button'

export const FullButton = styled(Button)`
  width: 100%;
  height: 57px;
  border-radius: 0px 0px 1px 1px;
  box-shadow: none;
  margin: 0px;
`
