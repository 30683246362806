import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.OptionHTMLAttributes<any> {}

const StyledOption = styled.option<IProps>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  height: 48px;
`

/**
 * Option
 */
/** @component */
export const Option = (props: IProps) => {
  return <StyledOption {...props}>{props.children}</StyledOption>
}
