import React from 'react'
import styled, { keyframes } from 'styled-components'

interface IProps {
  children: any
  isFinishedLoading: boolean
}

const RotatorKeyframe = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
`

const ColorsKeyframe = keyframes`
  0% { stroke: #4285F4; }
  25% { stroke: #DE3E35; }
  50% { stroke: #F7C223; }
  75% { stroke: #1B9A59; }
  100% { stroke: #4285F4; }
`

const Dashkeyframe = keyframes`
  0% { 
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Spinner = styled.svg`
  animation: ${RotatorKeyframe} 1.4s linear infinite;
`

const Path = styled.circle`
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  cx: 33;
  cy: 33;
  r: 30;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${Dashkeyframe} 1.4s ease-in-out infinite, ${ColorsKeyframe} 5.6s ease-in-out infinite;
`

export const FullscreenLoading = (props: IProps) => {
  let children = null,
    loader = null

  if (props.isFinishedLoading) children = props.children
  if (!props.isFinishedLoading)
    loader = (
      <Container>
        <Spinner width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <Path fill="none" stroke-width="60px" stroke-linecap="round" cx="33" cy="33" r="30" />
        </Spinner>
      </Container>
    )

  return (
    <>
      {children}
      {loader}
    </>
  )
}
