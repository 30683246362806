import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.HTMLAttributes<any> {
  color: string
}

const StyledBackground = styled.div<IProps>`
  position: absolute;
  width: 100%;
  height: 395px;
  top: 0;
  background-color: ${(props) => props.theme[props.color]}};
  z-index: -1;
`

export const Background = ({ ...props }: IProps) => <StyledBackground {...props} />
