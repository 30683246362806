import * as React from 'react'
import InputMask from 'react-input-mask'
import styled from 'styled-components'

interface IState {
  isFocus: boolean
  hasValue: boolean
  mask?: string
}

export interface IInputProps extends React.InputHTMLAttributes<any> {
  color?: string
  label: string
  temValor?: boolean
  hasError?: boolean
  errorMessage?: string | null | undefined
  innerRef?: any
  mask?: string
}

interface IInputLabelProps {
  themeColor: string
  isFocus: boolean
  hasValue: boolean
  temValor?: boolean
  hasError?: boolean
  disabled?: boolean
}

const StyledInputContainer = styled.div`
  margin: 8px;
  display: inline-flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
`

const StyledInput = styled.input<{
  hasError?: boolean
  isFocus: boolean
  themeColor: string
}>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;

  height: 22px;

  padding-bottom: 4px;

  border: 0;
  border-bottom: ${(props) => {
    if (props.hasError) return `1px solid ${props.theme.errorColor}`
    else if (props.isFocus) return `2px solid ${props.theme[props.themeColor]}`
    else return `1px solid ${props.theme.greyColor}`
  }};

  transition: border 150ms ease-out;

  outline: 0;

  &:disabled {
    background: transparent;
    border-bottom: 1px dashed ${(props) => props.theme.greyColor};
  }
`

const ErrorLabel = styled.label`
  font-size: calc(${(props) => props.theme.defaultFontSize} * 0.85);
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.errorColor};

  min-height: 22px;

  position: relative;
`

const StyledInputLabel = styled.label<IInputLabelProps>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  color: ${(props) => {
    if (props.disabled) return props.theme.disabledColor
    else if (props.hasError) return props.theme.errorColor
    else if (props.isFocus) return props.theme[props.themeColor]
    else return props.theme.greyTextColor
  }};

  position: absolute;
  left: 0;
  transform: ${(props) => (props.isFocus || props.hasValue || props.temValor ? 'translate3d(0, -100%, 0);font-size: 85%;' : '')};

  pointer-events: none;

  transition: font-size 200ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`

/**
 * Input
 */
/** @component */
export class Input extends React.Component<Partial<IInputProps>, IState> {
  constructor(props: IInputProps) {
    super(props)

    const hasValue =
      this.props.type === 'date' ||
      this.props.type === 'hora' ||
      this.props.disabled === true ||
      Boolean(this.props.value)

    this.state = {
      hasValue,
      isFocus: false,
      mask: undefined,
    }
  }

  focus = () => {
    if (this.props.type === 'telefone' && this.state.mask !== '(99) 99999-9999')
      this.setState({ mask: '(99) 99999-9999', isFocus: true })
    else if (this.props.type !== 'date' && this.props.type !== 'hora') this.setState({ isFocus: true })
  }

  blur = (e: any) => {
    const hasValue = e.target.value
    if (this.props.type === 'telefone' && this.state.mask === '(99) 99999-9999' && e.target.value.includes('_')) {
      e.target.value = e.target.value.replace(/[_|-]/g, '')
      e.target.value = e.target.value.substring(0, 9) + '-' + e.target.value.substring(9, 13)
      this.setState({ mask: '(99) 9999-9999', isFocus: false, hasValue })
    } else if (this.props.type !== 'date' && this.props.type !== 'hora') this.setState({ isFocus: false, hasValue })
  }

  change = (e: any) => {
    if (
      this.props.type === 'telefone' &&
      ((e.target.value.includes('_') && e.target.value.match(/_/g)!.length === 1) ||
        ((this.props.value as string).includes('_') && (this.props.value as string).match(/_/g)!.length === 1))
    ) {
      e.target.value = e.target.value.replace(/[_|-]/g, '')
      e.target.value = e.target.value.substring(0, 9) + '-' + e.target.value.substring(9, 13)
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps: IInputProps) => {
    const hasValue = nextProps.value !== '' || this.props.type === 'date' || this.props.type === 'hora'
    this.setState({ hasValue })
  }

  render() {
    const { label, style, color, hasError, errorMessage, id, mask, ...props } = this.props
    const ErrorDOM = hasError ? <div>{errorMessage}</div> : null

    const IdiomaDiferenteDePt =
      this.props.type === 'date' && navigator.language === 'en-US' ? (
        <div>
          {' '}
          <strong>ATENÇÃO: </strong>O sistema utiliza o idioma do seu navegador e o mesmo está em Inglês. Por gentileza
          utilize o formato de data Mês/Dia/Ano{' '}
        </div>
      ) : null

    const maskTelefone =
      props.type === 'telefone' &&
      (props.value as string).match(/\d/g) &&
      (props.value as string).match(/\d/g)!.length === 10
        ? '(99) 9999-9999'
        : '(99) 99999-9999'

    const input =
      mask || this.props.type === 'telefone' ? (
        <InputMask
          name={props.name}
          value={props.value}
          mask={this.props.type === 'telefone' ? this.state.mask || maskTelefone : mask!}
          onFocus={this.focus}
          onBlur={this.blur}
          disabled={this.props.disabled}
          onChange={this.change}
        >
          {/* {(inputProps: any) => (
            <StyledInput
              {...inputProps}
              id={id}
              themeColor={color!}
              hasError={hasError}
              onFocus={this.focus}
              onBlur={this.blur}
              isFocus={this.state.isFocus}
              disabled={this.props.disabled}
              onChange={this.change}
            />
          )} */}
        </InputMask>
      ) : (
        <StyledInput
          {...props}
          id={id}
          themeColor={color!}
          hasError={hasError}
          onFocus={this.focus}
          onBlur={this.blur}
          isFocus={this.state.isFocus}
        />
      )

    return (
      <StyledInputContainer style={style} {...props}>
        <StyledInputLabel
          htmlFor={id}
          isFocus={this.state.isFocus}
          hasValue={this.state.hasValue}
          temValor={props.temValor}
          themeColor={color!}
          hasError={hasError}
          disabled={props.disabled}
          hidden={props.hidden}
        >
          {label}
        </StyledInputLabel>
        {input}
        <ErrorLabel>{ErrorDOM}</ErrorLabel>
        <ErrorLabel>{IdiomaDiferenteDePt}</ErrorLabel>
      </StyledInputContainer>
    )
  }

  static defaultProps = {
    color: 'secondaryColor',
  }
}
