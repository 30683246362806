import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store'
import { SharedActions } from '../../store/Shared' 

export const useFormulario = () => {
  const dispatch = useDispatch()
  const formulario = useSelector((state: RootState) => state.Shared.formulario)
  const formularioApiState = useSelector((state: RootState) => state.Shared.apiStates.formularioAction)

  React.useEffect(() => {
    dispatch(SharedActions.formularioAction())
  }, [dispatch])


  return {
    formularioApiState,
    formulario,
  }
}