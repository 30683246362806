import * as React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 48px;
  height: 48px;

  position: absolute;
  right: 4px;
`

const StyledButton = styled.button`
  border: none;
  border-radius: 50%;

  background-color: transparent;
  color: ${(props) => props.theme.greyTextColor};

  cursor: pointer;

  position: relative;
  right: 0;

  width: 48px;
  height: 48px;

  transition: background-color 150ms ease-out, box-shadow 150ms ease-out;

  &:hover {
    background-color: ${(props) => LightenDarkenColor(props.theme.greyColor, 0.5)};
    box-shadow: none;
  }

  &:focus {
    background-color: ${(props) => LightenDarkenColor(props.theme.greyColor, 0.3)};
    outline: 0;
    box-shadow: none;
  }

  &:active {
    background-color: ${(props) => LightenDarkenColor(props.theme.greyColor, 0.1)};
  }
`

export const ListItemButton = ({ children, ...props }: React.ButtonHTMLAttributes<any>) => (
  <Container>
    <StyledButton className="material-icons" {...props}>
      {children}
    </StyledButton>
  </Container>
)

/* Helper Functions*/

const LightenDarkenColor = (color: string, percent: number) => {
  const f = parseInt(color.slice(1), 16)
  const t = percent < 0 ? 0 : 255
  const p = percent < 0 ? percent * -1 : percent
  const R = f >> 16
  const G = (f >> 8) & 0x00ff
  const B = f & 0x0000ff
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  )
}
