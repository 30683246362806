import * as React from 'react'
import { useDispatch } from 'react-redux'
import { AuthActions } from '../store'

export const SilentRenew = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(AuthActions.silentRenewAction())
  }, [dispatch])

  return <React.StrictMode></React.StrictMode>
}
