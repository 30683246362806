import { User, UserManager } from 'oidc-client'
import { AuthConstants } from './AuthConstants'

class AuthService {
  public userManager: UserManager
  constructor() {
    const settings = {
      authority: AuthConstants.authority,
      client_id: AuthConstants.clientId,
      redirect_uri: `${AuthConstants.redirectUri}signin-callback`,
      silent_redirect_uri: `${AuthConstants.redirectUri}silent-renew`,
      post_logout_redirect_uri: `${AuthConstants.redirectUri}`,
      response_type: AuthConstants.responseType,
      scope: AuthConstants.scope,
    }

    this.userManager = new UserManager(settings)
  }

  public getUser(): Promise<User | null> {
    return this.userManager.getUser()
  }

  public login(): Promise<void> {
    return this.userManager.clearStaleState().then(() => {
      this.userManager.signinRedirect()
    })
  }

  public renewToken(): Promise<User> {
    return this.userManager.signinSilent()
  }

  public getToken(): Promise<string> {
    return this.userManager.getUser().then((user) => (user ? user.access_token : ''))
  }

  public logout(): Promise<void> {
    return this.userManager.signoutRedirect()
  }

  public signinRedirectCallback = () => this.userManager.signinRedirectCallback()

  public signinSilentCallback = () => this.userManager.signinSilentCallback()
}

export default AuthService
