import styled from 'styled-components'
import { media } from '../../Themes'
import { Card } from './Card'

export const CardWithLeftMargin = styled(Card)`
  margin-left: 48px;
  ${media.zeroToDesktop(`        
    margin-left: 16px;
    margin-right: 16px;
  `)}
`
