import * as React from 'react'
import styled from 'styled-components'

interface IState {
  isFocus: boolean
  hasValue: boolean
  rows: number
  minRows: number
  maxRows: number
}

interface IProps extends React.TextareaHTMLAttributes<any> {
  color?: string
  label: string
  hasError?: boolean
  errorMessage?: string[] | string | undefined | null
  gridColumn?: string
}

interface IInputLabelProps {
  themeColor: string
  isFocus: boolean
  hasValue: boolean
  hasError?: boolean
  disabled?: boolean
}

const StyledTextareaContainer = styled.div<{
  gridColumn?: string
}>`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  grid-column: ${(props) => props.gridColumn};
  width: 100%;
`

const StyledTextarea = styled.textarea<{
  hasError?: boolean
  isFocus: boolean
  themeColor: string
}>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;
  min-height: 22px;
  max-height: 110px;
  width: 100%;

  padding-bottom: 4px;

  border: 0;
  border-bottom: ${(props) => {
    if (props.hasError) return `1px solid ${props.theme.errorColor}`
    else if (props.isFocus) return `2px solid ${props.theme[props.themeColor || 'primaryColor']}`
    else return `1px solid ${props.theme.greyColor}`
  }};

  transition: border 150ms ease-out;

  outline: 0;
  resize: none;

  &:disabled {
    background: transparent;
    border-bottom: 1px dashed ${(props) => props.theme.greyColor};
  }
`

const ErrorLabel = styled.label`
  font-size: calc(${(props) => props.theme.defaultFontSize} * 0.85);
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.errorColor};

  min-height: 22px;

  position: relative;
`

const StyledInputLabelSpan = styled.label<IInputLabelProps>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  color: ${(props) => {
    if (props.disabled) return props.theme.disabledColor
    else if (props.hasError) return props.theme.errorColor
    else if (props.isFocus) return props.theme[props.themeColor || 'primaryColor']
    else return props.theme.greyTextColor
  }};

  position: absolute;
  left: 0;
  transform: ${(props) =>
    props.isFocus || props.hasValue ? 'translate3d(0, -80%, 0); font-size: 85%;' : 'translate3d(0, 120%, 0)'};

  pointer-events: none;

  transition: font-size 200ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`

/**
 * Textarea
 */
/** @component */
export class Textarea extends React.Component<IProps, IState> {
  private textareaRef = React.createRef<HTMLTextAreaElement>()

  constructor(props: IProps) {
    super(props)

    const hasValue = this.props.disabled === true || this.props.value != undefined

    this.state = {
      hasValue,
      isFocus: false,
      rows: 1,
      minRows: 2,
      maxRows: 100,
    }
  }

  handleResize = () => {
    const textareaLineHeight = 18
    const { minRows, maxRows } = this.state

    if (this.textareaRef.current) {
      const textarea = this.textareaRef.current
      const previousRows = textarea.rows
      textarea.rows = minRows // reset number of rows in textarea

      const currentRows = ~~(textarea.scrollHeight / textareaLineHeight)

      if (currentRows === previousRows) {
        textarea.rows = currentRows
      }

      if (currentRows >= maxRows) {
        textarea.rows = maxRows
        textarea.scrollTop = textarea.scrollHeight
      }

      this.setState({
        rows: currentRows < maxRows ? currentRows : maxRows,
      })
    }
  }

  handleChange = (e: React.ChangeEvent<any>) => {
    this.handleResize()
    if (this.props.onChange) this.props.onChange(e)
  }

  focus = () => {
    this.setState({ isFocus: true })
  }

  blur = (e: any) => {
    const hasValue = e.target.value
    this.setState({ isFocus: false, hasValue })
  }

  componentDidMount() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  UNSAFE_componentWillReceiveProps = (nextProps: IProps) => {
    const hasValue = nextProps.value !== ''
    this.setState({ hasValue })
  }

  render() {
    const ErrorDOM = this.props.hasError ? <div>{this.props.errorMessage}</div> : null

    return (
      <StyledTextareaContainer gridColumn={this.props.gridColumn}>
        <label>
          <StyledInputLabelSpan
            isFocus={this.state.isFocus}
            hasValue={this.state.hasValue}
            themeColor={this.props.color!}
            disabled={this.props.disabled}
          >
            {this.props.label}
          </StyledInputLabelSpan>
          <StyledTextarea
            ref={this.textareaRef}
            {...this.props}
            onFocus={this.focus}
            onBlur={this.blur}
            onChange={this.handleChange}
            rows={this.state.rows}
            isFocus={this.state.isFocus}
            themeColor={this.props.color!}
          />
        </label>

        <ErrorLabel>{ErrorDOM}</ErrorLabel>
      </StyledTextareaContainer>
    )
  }
}
