import * as React from 'react'
import styled from 'styled-components'
import { LoadingIcon } from '../LoadingIcon/LoadingIcon'

/* Props Interface */
export interface IProps extends React.ButtonHTMLAttributes<any> {
  /** Cor do botão (primaryColor, primaryLightColor, primaryDarkColor, secondaryColor, secondaryLightColor, secondaryDarkColor, lightTextColor, darkTextColor, errorColor) */
  color?: string
  icon?: any
  isLoading?: boolean
  children?: any
  as?: any
  badge?: number
}

// #region Styles
/* Styled Component */
const StyledButton = styled.button<{
  color: string
  lightenDarkenColor: (...args: any[]) => any
}>`
  background-color: ${(props) => props.theme[props.color]};
  color: ${(props) => props.theme.lightTextColor};

  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.defaultFontSize};
  font-weight: 500;

  display: inline-block;

  border: none;
  border-radius: 2px;
  height: 36px;
  min-width: 64px;
  padding: 0 16px 0 16px;

  text-transform: uppercase;
  vertical-align: middle;

  cursor: pointer;

  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);

  transition: background-color 150ms ease-out, box-shadow 150ms ease-out;

  &:hover {
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], 0.1)};
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
  }

  &:focus {
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], -0.1)};
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    outline: 0;
  }

  &:active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], 0.2)};
  }

  &:disabled {
    background-color: ${(props) => props.theme.greyColor};
    color: ${(props) => props.theme.greyTextColor};
    box-shadow: none;
    cursor: default;
  }
`

const ButtonIcon = styled.i`
  position: relative;
  vertical-align: sub;

  font-size: 16px !important;
  margin-right: 8px;
  margin-left: -4px;
  transform: scale(1.3);
`

const StyledSpan = styled.span`
display: flex;
justify-content: space-between;
align-items: center;
  white-space: pre;
`
const Badge = styled.span`
    color: #212529;
    background-color: #f8f9fa;
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
`;

// #endregion Styles

/**
 * Button
 */
/** @component */
export const Button = ({ color = 'primaryColor',badge, type = 'button', isLoading = false, icon:Icon, ...props }: IProps) => {
  const renderIcon = Icon ? <Icon size={22} /> : null

  const LightenDarkenColor = (c: string = '#3F51B5', percent: number) => {
    const f = parseInt(c.slice(1), 16)
    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = f >> 16
    const G = (f >> 8) & 0x00ff
    const B = f & 0x0000ff
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    )
  }
  return (
    <StyledButton lightenDarkenColor={LightenDarkenColor} color={color!} type={type} {...props}>
      {isLoading ? (
        <LoadingIcon color={color} />
      ) : (
        <>
          {renderIcon}
          <StyledSpan>{props.children} {badge !== undefined && badge >= 0 && <Badge>{badge}</Badge>}</StyledSpan>
        </>
      )}
    </StyledButton>
  )
}
