import * as React from 'react'
import styled from 'styled-components'
import { media } from '../Themes'

interface IProps {
  title: string
}

const Container = styled.header`
  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.whiteColor};

  position: relative;

  height: 125px;
`

const StyledTitle = styled.h1`
  font-weight: 300;
  font-size: 40px;

  margin: 0;
  padding-left: 48px;

  position: absolute;
  top: 50%;
  transform: translate(0, -50%);

  flex: 1;

  ${media.zeroToDesktop(`
    font-size: 24px;
    padding-left: 16px;
  `)}
`

export const PageTitle = ({ title, children, ...props }: IProps & React.HTMLAttributes<any>) => (
  <Container {...props}>
    <StyledTitle>{title}</StyledTitle>
    {children}
  </Container>
)
