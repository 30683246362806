import * as PropTypes from 'prop-types'
import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.InputHTMLAttributes<any> {
  label: string
}

interface IOptional {
  checked: boolean
  onChange?: any
}

const Container = styled.label`
  position: relative;
  padding-left: 24px;

  cursor: pointer;
  margin: 5px 5px 5px 5px;

  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const Checkmark = styled.span`
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid ${(props) => props.theme.greyColor};

  box-sizing: border-box;

  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    display: none;
  }

  transition: all 0.1s ease-in-out;

  ${Container} &:after {
    box-sizing: border-box;
    position: absolute;

    top: 2.7px;
    left: 3px;

    width: 10px;
    height: 10px;
    border-radius: 50%;

    background: ${(props) => props.theme.primaryColor};
  }
`

const InputRadio = styled.input`
  position: absolute;
  cursor: pointer;
  opacity: 0;

  &:checked + ${Checkmark} {
    background: #ffffff;
    border-color: ${(props) => props.theme.primaryColor};
  }

  &:checked + ${Checkmark}:after {
    display: block;
  }
`

export class Radio extends React.Component<IProps, object> {
  static contextTypes = {
    radioGroup: PropTypes.object,
  }

  render() {
    const { name, selectedValue, onChange } = this.context.radioGroup
    const optional: IOptional = { checked: false }
    if (selectedValue !== undefined) {
      optional.checked = this.props.value === selectedValue
    }
    if (typeof onChange === 'function') {
      optional.onChange = onChange.bind(null, this.props.value)
    }

    return (
      <Container>
        {this.props.label}

        <InputRadio
          {...this.props}
          role="radio"
          aria-checked={optional.checked}
          type="radio"
          name={name}
          {...optional}
        />
        <Checkmark />
      </Container>
    )
  }
}
