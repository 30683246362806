import React from 'react'
import styled from 'styled-components'
import { Button } from './Button'

interface IProps {
  openTutorial: any
  voltar: any
}

const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const StyledButtonBackTutorial = styled(Button)`
  margin-left: 5px;
`

export const TutorialButtonAndBackButton: React.FC<IProps> = (props: IProps) => {
  return (
    <ContainerButtons>
      <StyledButtonBackTutorial type="button" color="primaryLightColor" onClick={props.openTutorial}>
        Tutorial de Uso
      </StyledButtonBackTutorial>
      <StyledButtonBackTutorial icon="arrow_back" type="button" color="primaryLightColor" onClick={props.voltar}>
        Voltar
      </StyledButtonBackTutorial>
    </ContainerButtons>
  )
}
