import * as React from 'react'
import styled from 'styled-components'

const StyledItemText = styled.p`
  margin: 0;
  color: ${(props) => props.color ? props.color : props.theme.greyTextColor};
  display: block;
  font-size: 1.1em;
  font-size: 14px;
  margin-bottom: 4px;
`

export const ListItemText = ({ children, ...props }: React.HTMLAttributes<any>) => {
  return <StyledItemText {...props}>{children}</StyledItemText>
}
