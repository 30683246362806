import styled from "styled-components";
import {
  Card,
  CardActions,
  CardTitle,
  Modal,
  Checkbox,
  Button,
} from "../../../Components";

const StyledModal = styled(Modal)<any>`
  top: 40px;
  overflow-y: scroll;
  max-height: 600px;
`;

type TermosEditalModalProp = {
  isModalOpen: boolean;
  setModal: (modalOpen: boolean) => void;
  aceitarTermo: () => void;
};

export function TermosEditalModal({
  isModalOpen,
  setModal,
  aceitarTermo,
}: TermosEditalModalProp) {

  const accept = () => {
    setTimeout(() => {
      aceitarTermo();
      setModal(false);
    }, 1500);
  };

  return (
    <StyledModal isVisible={isModalOpen} outterClick={() => {}} size={"small"}>
      <Card>
        <CardTitle> Leia as informações do edital.</CardTitle>
        <Button
          style={{
            marginTop: "25px",
            marginBottom: "25px",
            marginLeft: "250px",
          }}
          onClick={() => window.open("https://drive.google.com/file/d/1n8aiTSfXGP6O73LNkJO0Tei2v5gNBFtO/view?usp=drive_link")}
        >
          {" "}
          Abrir edital do Promais 2024
        </Button>
        <CardActions>
          <Checkbox
            label="Eu li e aceito os termos do edital."
            onClick={accept}
          />
        </CardActions>
      </Card>
    </StyledModal>
  );
}
