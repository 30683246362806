import * as React from 'react'
import styled from 'styled-components'
import { TabItem } from './TabItem'
import { Panel, TabPanel } from './TabPanel'

interface TabComponentProps {
  activeChild: string
  onTabComponentClick?: () => any
}

interface TabComponentState {
  activeChild: string
}

const StyledTabComponent = styled.div`
  position: relative;
`
const StyledList = styled.div`
  position: relative;
  display: flex;
  border: 1px solid ${(props) => props.theme.greyColor};
  border-radius: 2px;

  @media (max-width: 760px) {
    flex-direction: column;
  }
`

const StyledBadge = styled.div`
  background-color: ${(props) => props.theme.primaryColor};
  border-radius: 10px;
  width: 1.2em;
  height: 1.2em;
  margin-left: 6px;
  text-align: center;
  color: white;
`

const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export class TabComponent extends React.Component<TabComponentProps, TabComponentState> {
  constructor(props: TabComponentProps) {
    super(props)
    this.state = {
      activeChild:
        this.props.activeChild ||
        ((this.props.children as Array<React.ReactElement>)[0] &&
          (this.props.children as Array<React.ReactElement>)[0].props.titulo) ||
        '',
    }
  }

  onTabClick = (elementIndex: string) => {
    this.setState({ activeChild: elementIndex })
    this.props.onTabComponentClick && this.props.onTabComponentClick()
  }

  render() {
    const children = (
      (Array.isArray(this.props.children)
        ? this.props.children
        : [this.props.children as React.ReactElement]) as Array<React.ReactElement>
    ).filter((c) => c.type === TabPanel)
    return (
      <StyledTabComponent>
        <StyledList>
          {children.map((c, i) => (
            <TabItem
              key={i}
              active={this.state.activeChild === c.props.titulo + (c.props.subtitulo || '')}
              click={this.onTabClick}
              title={c.props.titulo}
              {...c.props}
            >
              <StyledTitle>
                <div>{c.props.titulo}</div>
                <div>{c.props.subtitulo}</div>
              </StyledTitle>
              {c.props.badge && <StyledBadge>{c.props.badge}</StyledBadge>}
            </TabItem>
          ))}
        </StyledList>
        {children.map((c, i) => {
          return (
            <Panel key={i} active={this.state.activeChild === c.props.titulo + (c.props.subtitulo || '')} {...c.props} />
          )
        })}
      </StyledTabComponent>
    )
  }
}
