import * as React from 'react'
import styled from 'styled-components'
import { ListItemText } from './ListItemText'
import { ListItemTitle } from './ListItemTitle'

const StyledListItem = styled.li`
  padding: 12px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;

  &:hover {
    background: ${(props) => props.theme.greyColor};
    cursor: pointer;
  }
`

const StyledTextContent = styled.div`
  min-width: 50%;
  flex-grow: 1;
`

export const ListItem = ({ children, ...props }: React.LiHTMLAttributes<any>) => {
  const childrenComponents = (Array.isArray(children) ? children : [children]) as Array<React.ReactElement>

  const textComponents = childrenComponents.filter((c) => c && (c.type == ListItemText || c.type == ListItemTitle))

  const actionComponent = childrenComponents.filter((c) => c && c.type != ListItemText && c.type != ListItemTitle)

  return (
    <StyledListItem {...props}>
      <StyledTextContent>{textComponents}</StyledTextContent>
      {actionComponent}
    </StyledListItem>
  )
}
