import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.whiteColor};
`

export const Loading = () => <StyledContainer>Carregando</StyledContainer>
