/* eslint-disable indent */
import * as styledComponents from 'styled-components'
import { ThemedStyledComponentsModule } from 'styled-components'

const { css, keyframes, ThemeProvider } = styledComponents as ThemedStyledComponentsModule<any>

export interface IThemeInterface {
  fontFamily: string
  defaultFontSize: string
  primaryColor: string
  primaryLightColor: string
  primaryDarkColor: string
  secondaryColor: string
  secondaryLightColor: string
  secondaryDarkColor: string
  greyColor: string
  whiteColor: string
  greyTextColor: string
  lightTextColor: string
  darkTextColor: string
  lightGreyColor: string
  errorColor: string
  warningColor: string
  approvedColor: string
  disabledColor: string
}

class MediaQuery {
  desktop = (args: string) => this.mediaQueryConstructor(args, 1025)
  tablet = (args: string) => this.mediaQueryConstructor(args, 640)
  phone = (args: string) =>
    css`
      ${args}
    `.join('')

  zeroToPhone = (args: string) => this.mediaQueryConstructor(args, 0, 320)
  zeroToTablet = (args: string) => this.mediaQueryConstructor(args, 0, 640)
  zeroToDesktop = (args: string) => this.mediaQueryConstructor(args, 0, 1024)

  mediaQueryConstructor = (args: string, widthInPixel: number, maxWidthInPixel: number = 0) => {
    return css`
      @media (min-width: ${widthInPixel / 16}em) ${maxWidthInPixel > 0
          ? ' and (max-width: ' + maxWidthInPixel / 16 + 'em)'
          : ''} {
        ${css`
          ${args}
        `}
      }
    `.join('')
  }
}

export const theme: IThemeInterface = {
  fontFamily: 'Roboto, sans-serif',
  defaultFontSize: '14px',
  primaryColor: '#3f51b5',
  primaryLightColor: '#757ce8',
  primaryDarkColor: '#002884',
  secondaryColor: '#2194f3',
  secondaryLightColor: '#6ec4ff',
  secondaryDarkColor: '#0067c0',
  greyColor: '#E0E0E0',
  whiteColor: '#ffffff',
  greyTextColor: '#424242',
  lightTextColor: '#ffffff',
  darkTextColor: '#000000',
  lightGreyColor: '#fafafa',
  errorColor: '#EF5350',
  warningColor: '#FF8800',
  approvedColor: '#66BB6A',
  disabledColor: '#9E9E9E',
}

export const media = new MediaQuery()

export { css, keyframes, ThemeProvider }
