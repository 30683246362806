import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { useFormulario } from "../../hooks/StoreHooks/useFormulario";
import { Aluno, Gabarito } from "../../domain/Shared";
import { Formulario } from "../../domain/Shared/Formulario";
import { CardContent } from "../../Components";
import styled from "styled-components";
import { useAluno } from "../../hooks/StoreHooks/useAluno";
import { TermosEditalModal } from "./Components/TermosEditalModal";
import { faseAtual } from "../../helper/FaseAtual";

const StyledCardContent = styled(CardContent)`
  font-size: 20px;
  font-weight: 600;
  background-color: white;
`

const AlunoPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "AlunoPagina" */ "./AlunoPagina"
    )
)

const EnderecoPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "EnderecoPagina" */ "./EnderecoPagina"
    )
)

const OutrasInformacoesPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "OutrasInformacoesPagina" */ "./OutrasInformacoesPagina"
    )
)

const GrupoFamiliarPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "GrupoFamiliarPagina" */ "./GrupoFamiliarPagina"
    )
)

const RecursoPagina = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "RecursoPagina" */ "./RecursoPagina"
    )
)

const NotFoundPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      /* webpackChunkName: "NotFoundPage" */ "../NotFound/index"
    )
)

function RenderComponent(
  path: string, 
  termo: () => void,
  aluno?: Aluno, 
  formulario?: Formulario | undefined | null,
  gabarito?: Gabarito | undefined | null){
  const [modal, setModal] = React.useState(true);

  if (aluno === undefined || formulario === undefined) 
    return <StyledCardContent>Carregando...</StyledCardContent>

  if(!formulario)formulario = null

  if (aluno === null || aluno.alunoAtivo !== true) return <NotFoundPage />
  if (formulario === null) return <StyledCardContent>Fora de Período</StyledCardContent>

  if(!aluno.termo) return <TermosEditalModal
                            isModalOpen={aluno?.termo && aluno?.termo ? false : modal}
                            setModal={setModal}
                            aceitarTermo={termo}
                          />

  let faseA = faseAtual(formulario)

  if(faseA === 'recurso') path='/recurso'

  if (aluno && formulario){
    if (path === '/') return <AlunoPagina />
    if (path === '/endereco') return <EnderecoPagina />
    if (path === '/outrasinformacoes' && gabarito) return <OutrasInformacoesPagina />
    if (path === '/grupofamiliar' && gabarito) return <GrupoFamiliarPagina />
    if (path === '/recurso' && gabarito) return <RecursoPagina />
  }

  return <NotFoundPage />
}

export const AlunoIndexPage: React.FC = () => {
  const { formulario } = useFormulario();
  const { aluno, gabarito, aceitarTermo } = useAluno();

  return (
    <React.Suspense
      fallback={
        <p>
          <strong>Carregando...</strong>
        </p>
      }
    >
    <Routes>
      <Route path={'/'} element={RenderComponent("/", aceitarTermo, aluno, formulario, gabarito)} />
      <Route path={'/outrasinformacoes'} element={RenderComponent(
        "/outrasinformacoes",aceitarTermo, aluno, formulario, gabarito)} />
      <Route path={'/grupofamiliar'} element={RenderComponent(
        "/grupofamiliar", aceitarTermo, aluno, formulario, gabarito)} />
      <Route path={'/endereco'} element={RenderComponent(
        "/endereco", aceitarTermo, aluno, formulario, gabarito)} />
      <Route path={'/recurso'} element={RenderComponent(
        "/recurso", aceitarTermo, aluno, formulario, gabarito)} />
    </Routes>
    </React.Suspense>
  );
};
