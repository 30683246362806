import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { apiInstance, apiState, ApiStates, getStateByStatus } from '../../api'

interface ISharedState {
  formulario?: any
  apiStates: ApiStates<typeof customActions>
}

let initialState: ISharedState = {
  apiStates: {},
}

const sharedSlicer = createSlice({
  name: 'sharedSlicer',
  initialState,
  reducers: {
    setApiState: (state, action: PayloadAction<ApiStates<typeof customActions>>) => {
      state.apiStates = { ...state.apiStates, ...action.payload }
    },
    setFormulario: (state, action: PayloadAction<any>) => {
        state.formulario = action.payload
    },
  },
})

const customActions = {
    formularioAction: () => {
        return async (dispatch: any) => {
          try {
            dispatch(sharedSlicer.actions.setApiState({formularioAction: { state: apiState.loading } }))
    
            let result = await apiInstance.get<any>('formulario')
    
            dispatch(sharedSlicer.actions.setFormulario(result.data))
    
            dispatch(sharedSlicer.actions.setApiState({formularioAction: { state: getStateByStatus(result.status) } }))
          } catch (error: any) {
            dispatch(sharedSlicer.actions.setApiState({formularioAction: { state: apiState.error, error: error } }))
          }
        }
      },
}

const SharedReducer = sharedSlicer.reducer
const SharedActions = { ...customActions }

export { SharedReducer,SharedActions }
