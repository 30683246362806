import * as React from 'react'
import styled from 'styled-components'

interface IState {
  isFocus: boolean
  hasValue: boolean
}

interface IProps extends React.SelectHTMLAttributes<any> {
  label: string
  color?: string
  hasError?: boolean
  errorMessage?: string
}

interface IInputLabelProps {
  color?: string
  isFocus: boolean
  hasValue: boolean
  hasError?: boolean
  disabled?: boolean
}

const StyledSelectContainer = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
`

const StyledSelect = styled.select<{ hasError?: boolean; isFocus: boolean }>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;

  height: 22px;

  padding-bottom: 4px;

  border: 0;
  border-bottom: ${(props) => {
    if (props.hasError) return `1px solid ${props.theme.errorColor}`
    else if (props.isFocus)
      if (props.color) return `2px solid ${props.theme[props.color]}`
      else return `2px solid ${props.theme.secondaryColor}`
    else return `1px solid ${props.theme.greyColor}`
  }};

  transition: border 150ms ease-out;

  outline: 0;

  &:disabled {
    background: transparent;
    border-bottom: 1px dashed ${(props) => props.theme.greyColor};
  }
`

const StyledInputLabel = styled.label<IInputLabelProps>`
  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  color: ${(props) => {
    if (props.disabled) return props.theme.disabledColor
    else if (props.hasError) return props.theme.errorColor
    else if (props.isFocus) return props.color ? props.theme[props.color] : props.theme.secondaryColor
    else return props.theme.greyTextColor
  }};

  position: absolute;
  left: 0;
  transform: translate3d(0, -100%, 0);
  font-size: 85%;

  pointer-events: none;

  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`

const ErrorLabel = styled.label`
  font-size: calc(${(props) => props.theme.defaultFontSize} * 0.85);
  font-family: ${(props) => props.theme.fontFamily};
  color: ${(props) => props.theme.errorColor};

  min-height: 22px;

  position: relative;
`

/**
 * Select
 */
/** @component */
export class Select extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      hasValue: false,
      isFocus: false,
    }
  }

  focus = () => {
    this.setState({ isFocus: true })
  }

  blur = (e: any) => {
    const hasValue = e.target.value
    this.setState({ isFocus: false, hasValue })
  }

  render() {
    const { label, color, hasError, errorMessage, id, ...props } = this.props
    const ErrorDOM = hasError ? <div>{errorMessage}</div> : null
    return (
      <StyledSelectContainer {...props}>
        <StyledInputLabel
          isFocus={this.state.isFocus}
          hasValue={this.state.hasValue}
          color={color}
          htmlFor={id}
          disabled={props.disabled}
          hidden={props.hidden}
          hasError={hasError}
        >
          {label}
        </StyledInputLabel>
        <StyledSelect
          {...props}
          id={id}
          onFocus={this.focus}
          onBlur={this.blur}
          isFocus={this.state.isFocus}
          hasError={hasError}
        >
          {props.children}
        </StyledSelect>
        <ErrorLabel>{ErrorDOM}</ErrorLabel>
      </StyledSelectContainer>
    )
  }
}
