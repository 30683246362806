/* eslint-disable linebreak-style */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import AuthService from '../../auth/AuthService'
import { User } from 'oidc-client'
import { CurrentUserModel } from '../../domain/Auth/CurrentUser'

interface IAuthState {
  user?: User | null
  currentUser?: CurrentUserModel
}

const authService = new AuthService()

const initialState: IAuthState = {}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      const user = action.payload
      state.user = user
      if (user) {
        state.currentUser = {
          name: user.profile.Nome,
          cpf: user.profile.CPF,
          tipoUsuario:
          user.profile.Vinculo === '0'?
              ['aluno']:
            ((Array.isArray(user.profile.role) && user.profile.role) || [user.profile.role])
              .filter((role: string) => role.includes('Promais'))
              .map((role: string) => role.replace('Promais.', '').replace(/\..*/g, '').toLowerCase()) || [],
          categorias: Object.assign(
            {},
            ...Object.keys(user.profile)
              .filter((key) => key.includes('Promais.Categoria') && !user.profile[key].includes('Sem Permissao'))
              .sort((keyA, keyB) => keyA.localeCompare(keyB))
              .map((key) => ({
                [key.replace('Promais.Categoria.', '')]: user.profile[key],
              }))
          ),
        }
      }
    },
  },
})

const customActions = {
  signinRedirectCallbackAction: () => {
    return async (dispatch: any) => {
      let user = await authService.getUser()
      if (user === null || user === undefined) {
        user = await authService.signinRedirectCallback()
      }
      dispatch(AuthActions.setUser(user))
    }
  },
  tokenRenewAction: () => {
    return async (dispatch: any) => {
      let result: User | null = await authService.getUser()
      if (result && result.expired) {
        await authService.renewToken()
        result = await authService.getUser()
      }
      dispatch(AuthActions.setUser(result))
    }
  },
  loginAction: () => {
    return async (dispatch: any) => {
      let result: User | null = await authService.getUser()
      if (!result) {
        await authService.login()
        result = await authService.getUser()
      }
      dispatch(AuthActions.setUser(result))
    }
  },
  silentRenewAction: () => {
    return async (dispatch: any) => {
      const result: User | undefined = await authService.signinSilentCallback()

      if (result) {
        dispatch(AuthActions.setUser(result))
      }
    }
  },
}

const AuthReducer = authSlice.reducer
const AuthActions = {
  ...authSlice.actions,
  ...customActions,
}

export { AuthReducer, AuthActions }
