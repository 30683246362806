import * as React from 'react'
import styled from 'styled-components'

interface IProps extends React.HTMLAttributes<any> {
  showBorder?: boolean
}

const StyledList = styled.ul<{
  showBorder?: boolean
}>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.defaultFontSize};

  background-color: white;
  ${(props) => {
    if (props.showBorder == undefined || props.showBorder == true) {
      return `border: 1px solid ${props.theme.greyColor};
              border-radius: 2px;`
    } else return 'border: none;'
  }}

  position: relative;
  padding: 8px 0 8px 0;
  margin: 0px;

  list-style: none;
`

export const List = ({ children, ...props }: IProps) => <StyledList {...props}>{children}</StyledList>
