import Axios, { AxiosError } from 'axios'
import AuthService from '../auth/AuthService'

export const apiInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const authService = new AuthService()

apiInstance.interceptors.request.use(async (config) => {
  const token = await authService.getToken()
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  }

  return config
})

export interface apiResponse<T> {
  status: apiState
  data: T
}

export enum apiState {
  loading,
  done,
  noContent,
  badRequest,
  error,
  notFound,
}

export type ApiStates<T> = {
  [key in keyof T]?: { state: apiState; error?: AxiosError }
}

export const getStateByStatus = (status: number): apiState => {
  switch (status) {
    case 200:
      return apiState.done

    case 204:
      return apiState.noContent

    case 400:
      return apiState.badRequest

    case 404:
      return apiState.notFound

    case 500:
      return apiState.error

    default:
      return apiState.error
  }
}

