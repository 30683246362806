import * as React from 'react'
import styled from 'styled-components'

const StyledItemTitle = styled.span`
  display: block;
  font-size: 16px;
  text-overflow: ellipsis;
  font-weight: bold;
  margin-bottom: 8px;
  max-width: 600px;
`

export const ListItemTitle = ({ children, ...props }: React.HTMLAttributes<any>) => {
  return <StyledItemTitle {...props}>{children}</StyledItemTitle>
}
