import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { apiInstance, apiState, ApiStates, getStateByStatus } from "../../api"
import { ProcessoDetalhes } from "../../domain/Shared/ProcessoDetalhes"
import { toast } from "react-toastify"
import { Recurso } from "../../domain/Shared/Recurso"
import { ClassificadoViewModel, ProcessoViewModel } from "../../domain/Shared/ViewModel"
import { Comissao } from "../../domain/Shared/Comissao"
import { Arquivo } from "../../domain/Shared"


interface IComissaoState {
  processo?: ProcessoViewModel
  classificacao?: ClassificadoViewModel
  processoDetalhes?: ProcessoDetalhes
  recurso?: Recurso[]
  comissao?: Comissao[]
  arquivo?: Arquivo
  apiStates: ApiStates<typeof customActions>
}

let initialState: IComissaoState = {
  apiStates: {},
}

const comissaoSlicer = createSlice({
  name: "comissaoSlicer",
  initialState,
  reducers: {
    setApiState: (
      state,
      action: PayloadAction<ApiStates<typeof customActions>>
    ) => {
      state.apiStates = { ...state.apiStates, ...action.payload }
    },
    setProcesso: (state, action: PayloadAction<ProcessoViewModel>) => {
      state.processo = action.payload
    },
    setComissao: (state, action: PayloadAction<Comissao[]>) => {
      state.comissao = action.payload
    },
    setArquivo: (state, action: PayloadAction<Arquivo>) => {
      state.arquivo = action.payload
    },
    setProcessoDetalhes: (state, action: PayloadAction<ProcessoDetalhes>) => {
      state.processoDetalhes = action.payload
    },
    setRecurso: (state, action: PayloadAction<any>) => {
      state.recurso = action.payload
    },
    setClassificacao: (state, action: PayloadAction<any>) => {
      state.classificacao = action.payload
    },
  },
})

const customActions = {
  classificacaoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            classificacaoAction: { state: apiState.loading },
          })
        )
        const result = await apiInstance.get('comissao/classificacao')
        
        dispatch(comissaoSlicer.actions.setClassificacao(result.data))

        dispatch(
          comissaoSlicer.actions.setApiState({
            classificacaoAction: { state: getStateByStatus(result.status) },
          })
        )
      } catch (error: any) {
        dispatch(
          comissaoSlicer.actions.setApiState({
            classificacaoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  processoAction: (fase: number = 0) => {
    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            processoAction: { state: apiState.loading },
          })
        )
        const result = await apiInstance.get(`comissao/participante/processo/${fase}`)
        
        dispatch(comissaoSlicer.actions.setProcesso(result.data))

        dispatch(
          comissaoSlicer.actions.setApiState({
            processoAction: { state: getStateByStatus(result.status) },
          })
        )
      } catch (error: any) {
        dispatch(
          comissaoSlicer.actions.setApiState({
            processoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  comissaoAction: (fase: number = 0) => {
    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            comissaoAction: { state: apiState.loading },
          })
        )
        const result = await apiInstance.get("comissao/participante/membro")
        
        dispatch(comissaoSlicer.actions.setComissao(result.data))

        dispatch(
          comissaoSlicer.actions.setApiState({
            comissaoAction: { state: getStateByStatus(result.status) },
          })
        )
      } catch (error: any) {
        dispatch(
          comissaoSlicer.actions.setApiState({
            comissaoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  aprovarRecursoAction: (data: any, status: string ) => {

    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarRecursoAction: { state: apiState.loading },
          })
        )
        let result;
        if(status === 'aprovar') {
          result = await apiInstance.post('comissao/recurso/aprovar', data)
          if(result.status === 200) toast.success("Recurso aprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarRecursoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === 'reprovar') {
          result = await apiInstance.post('comissao/recurso/reprovar', data)
          if(result.status === 200) toast.success("Recurso reprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarRecursoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === '' || status === null || status === undefined) toast.error("Um erro ocorreu!");
        
      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarRecursoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  retornarArquivoAction: (
    documentoId: string | undefined, 
    tipoDocumentoId: string | undefined,
    anexoId?: string | undefined
    ) => {
    return async (dispatch: any) => {
      try {
        dispatch(comissaoSlicer.actions.setApiState({ retornarArquivoAction: { state: apiState.loading } }))

        const result = await apiInstance.get<Arquivo>('comissao/arquivo', { params: {
          documentoId, tipoDocumentoId, anexoId
        }})

        dispatch(comissaoSlicer.actions.setArquivo(result.data))

        dispatch(comissaoSlicer.actions.setApiState({ retornarArquivoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(comissaoSlicer.actions.setApiState({ retornarArquivoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  aprovarDocumentoAction: (data: any, status: string ) => {

    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarDocumentoAction: { state: apiState.loading },
          })
        )
        let result;
        if(status === 'aprovar') {
          result = await apiInstance.post('comissao/documento/aprovar', data)
          if(result.status === 200) toast.success("Documento aprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarDocumentoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === 'reprovar') {
          result = await apiInstance.post('comissao/documento/reprovar', data)
          if(result.status === 200) toast.success("Documento reprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarDocumentoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === '' || status === null || status === undefined) toast.error("Um erro ocorreu!");
        
      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarDocumentoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  aprovarProcessoAction: (data: any, status: string ) => {

    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarProcessoAction: { state: apiState.loading },
          })
        )
        let result;
        if(status === 'aprovar') {
          result = await apiInstance.post('comissao/processo/aprovar', data)
          if(result.status === 200) toast.success("Processo aprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarProcessoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === 'reprovar') {
          result = await apiInstance.post('comissao/processo/reprovar', data)
          if(result.status === 200) toast.success("Processo reprovado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              aprovarProcessoAction: { state: getStateByStatus(result.status) },
            })
          )
        } 
        if(status === '' || status === null || status === undefined) toast.error("Um erro ocorreu!");
        
      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(
          comissaoSlicer.actions.setApiState({
            aprovarProcessoAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  atualizarMembroAction: (analiseGabaritoId: number, comissaoId: number ) => {

    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            atualizarMembroAction: { state: apiState.loading },
          })
        )
          const result = await apiInstance.post('comissao/atualizar', {analiseGabaritoId, comissaoId})
          if(result.status === 200) toast.success("Membro atualizado com sucesso!");
          dispatch(
            comissaoSlicer.actions.setApiState({
              atualizarMembroAction: { state: getStateByStatus(result.status) },
            })
          )

      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(
          comissaoSlicer.actions.setApiState({
            atualizarMembroAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
  listarRecursoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(comissaoSlicer.actions.setApiState({ listarRecursoAction: { state: apiState.loading } }))

        const result = await apiInstance.get('comissao/recurso')

        dispatch(comissaoSlicer.actions.setRecurso(result.data))

        dispatch(comissaoSlicer.actions.setApiState({ listarRecursoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(comissaoSlicer.actions.setApiState({ listarRecursoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  processoDetalhesAction: (id: number, analiseComissaoId?:number) => {
    return async (dispatch: any) => {
      try {
        dispatch(
          comissaoSlicer.actions.setApiState({
            processoDetalhesAction: { state: apiState.loading },
          })
        )

        const result = await apiInstance.get<ProcessoDetalhes>(`comissao/participante/detalhe/${id}/${analiseComissaoId}`)

        dispatch(comissaoSlicer.actions.setProcessoDetalhes(result.data))

        dispatch(
          comissaoSlicer.actions.setApiState({
            processoDetalhesAction: { state: getStateByStatus(result.status) },
          })
        )
      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(
          comissaoSlicer.actions.setApiState({
            processoDetalhesAction: { state: apiState.error, error: error },
          })
        )
      }
    }
  },
}

const ComissaoReducer = comissaoSlicer.reducer
const ComissaoActions = { ...customActions }

export { ComissaoReducer, ComissaoActions }
