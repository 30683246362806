import { Route, Routes} from 'react-router-dom'
import { RootState } from './store'
import { useSelector } from 'react-redux'
import { useAuth } from './hooks'
import NotFoundPage from './pages/NotFound'
import { Navbar, NavbarTitle, Container, Background, Button } from './Components'
import styled from 'styled-components'
import Logo from './Assets/logo.png'
import { PageTitle } from './pages/Shared/PageTitle'
import AuthService from './auth/AuthService'
import "./styles/global.css"
import {AlunoIndexPage} from './pages/Aluno'
import { ComissaoIndexPage } from './pages/Comissao'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
const StyledButton = styled(Button)`
  margin-left: auto;
`

const StyledAnchor = styled.a`
  text-decoration: none;
`

const LabelPerfil = styled.span`
  display: block;
  font-size: 14px;
  font-weight: normal;
  margin-top: 6px;
`

const App = () => {
  const { isLoggedIn } = useAuth()
  const currentUser = useSelector((state: RootState) => state.Auth.currentUser)

  const logout = () => {
    const authService = new AuthService()
    authService.logout()
  }

  return (
    <>
      {isLoggedIn && (
        <>
        <ToastContainer autoClose={3000} />
          <Navbar color="primaryColor" isFlat={true}>
            <NavbarTitle>
              {currentUser?.name} 
              <LabelPerfil>
                ({currentUser?.tipoUsuario.toString() === 'comissao' ? 'Comissão' : currentUser?.tipoUsuario.toString()})
              </LabelPerfil>
            </NavbarTitle>
            <StyledButton type="button" color="primaryLightColor" onClick={logout}>
              Sair
            </StyledButton>
          </Navbar>
          <Background color="primaryColor" />
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <StyledAnchor>
              <img style={{ width: '10rem' }} src={Logo} alt={''}/>
              <PageTitle />
            </StyledAnchor>
          </div>
          <Container style={{ width: '960px' }} phone="100%" tablet="75%">
            <Routes>
              <Route path="/comissao/*" element={<ComissaoIndexPage/>} />
              <Route path="/aluno/*" element={<AlunoIndexPage/>} />
              <Route path="*" element={<NotFoundPage/>} />
            </Routes>
          </Container>
        </>
      )}
    </>
  )
}
export default App
