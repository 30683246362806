import * as React from 'react'
import styled from 'styled-components'

interface IProps {
  right: boolean
}

const StyledNavbarIcon = styled.button<IProps>`
  padding: 0 14px;

  ${(props) => (props.right ? 'margin-left: auto' : '')}

  border: none;
  background: none;

  color: ${(props) => props.theme.lightTextColor};
  cursor: pointer;
`

export const NavbarIcon = ({ children, className, ...props }: React.ButtonHTMLAttributes<any> & IProps) => {
  return (
    <StyledNavbarIcon type="button" className={className + ' material-icons'} {...props}>
      {children}
    </StyledNavbarIcon>
  )
}
