import * as React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  background: rgba(255, 255, 255, 0.15);

  font-size: ${(props) => props.theme.defaultFontSize};
  font-family: ${(props) => props.theme.fontFamily};

  margin-left: 8px;
  margin-right: 16px;
  border-radius: 2px;

  display: block;
  box-sizing: border-box;
  position: relative;
`

const IconContainer = styled.div`
  width: 72px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: center;
  pointer-events: none;
  justify-content: center;
`
const Icon = styled.i`
  width: 1em;
  height: 1em;
`

const InputContainer = styled.span`
  position: relative;
  display: inline-block;
  direction: ltr;

  box-sizing: inherit;
`
const StyledSearchInput = styled.input` 
  font-size inherit;
  font-family: inherit;
  position: relative;
  vertical-align: top;

  background: none;
  color: white;  
  
  width: 200px;    
  border: 0;
  margin: 0;
  padding: 10px 8px 10px 72px;
  display: block;  
  

  box-sizing: inherit;
  white-space: normal;

  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:focus {
    width: 250px;
    outline: 0;
  }
`

export const NavbarSearchInput = (props: React.InputHTMLAttributes<any>) => (
  <StyledContainer>
    <IconContainer>
      <Icon className="material-icons">search</Icon>
    </IconContainer>
    <InputContainer>
      <StyledSearchInput {...props} />
    </InputContainer>
  </StyledContainer>
)
