import * as React from 'react'
import { Card } from '..'
import { apiState } from '../../api'
import { CardContent } from '../Card'
import styled from 'styled-components'

type Props = {
  state: apiState | undefined
  loading?: React.ComponentType<any> | string | (() => JSX.Element)
  notFound?: React.ComponentType<any> | string | (() => JSX.Element)
  noContent?: React.ComponentType<any> | string | (() => JSX.Element)
}

const StyledCardContent = styled(CardContent)`
  font-size: 16px;
  font-weight: 400;
`

export const withStateCard = <P extends object>(Component: React.ComponentType<P>) =>
  class WithLoading extends React.Component<P & Props> {
    render() {
      const { state, loading, notFound, noContent, ...props } = this.props

      switch (state) {
        case apiState.loading:
          return renderOrDefault(loading, 'Carregando...')

        case apiState.done:
          return <Component {...(props as P)} />

        case apiState.noContent:
          return renderOrDefault(noContent, 'Nenhum item encontrado')

        case apiState.badRequest:
          return <Component {...(props as P)} />

        case apiState.notFound:
          return renderOrDefault(notFound, 'Não encontrado')

        case apiState.error:
        default:
          return (
            <Card>
              <StyledCardContent>Erro desconhecido</StyledCardContent>
            </Card>
          )
      }
    }
  }

const isComponent = (c: any): c is React.ComponentType<any> => c && c.render && typeof c.render === 'function'
const isFunction = (f: any): f is () => JSX.Element => typeof f === 'function'

const renderOrDefault = (element: any, defaultMessage: string) => {
  if (isComponent(element)) {
    const Element = element as React.ComponentType<any>
    return <Element />
  } else if (isFunction(element)) {
    return element()
  } else {
    return (
      <Card>
        <StyledCardContent>{element ? element : defaultMessage}</StyledCardContent>
      </Card>
    )
  }
}
