import * as React from 'react'
import styled from 'styled-components'

export interface PanelProps {
  titulo: string
  subtitulo?: string
  active: boolean
  badge?: string
  onClick?: () => void
}

const StyledTabPanel = styled.div<{
  active: boolean
}>`
  display: ${(props) => (props.active ? 'block' : 'none')};
`
export class TabPanel extends React.Component<Partial<PanelProps>> {}

export class Panel extends React.Component<PanelProps> {
  render() {
    return <StyledTabPanel {...this.props} />
  }
}
