import * as React from 'react'
import styled from 'styled-components'

const StyledActionContent = styled.div`
  display: flex;
`

export const ListItemActions = ({ children }: React.HTMLAttributes<any>) => (
  <StyledActionContent onClick={(e) => e.stopPropagation()}>{children}</StyledActionContent>
)
