import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { apiInstance, apiState, ApiStates, getStateByStatus } from '../../api'
import { Aluno, Arquivo, Documento, Endereco, MembroFamiliar, Pensao } from '../../domain/Shared'
import { Recurso } from '../../domain/Shared/Recurso'
import { toast } from 'react-toastify'

interface IAlunoState {
  aluno?: Aluno
  gabarito?: any
  analiseGabarito?: any
  membros?: MembroFamiliar[]
  endereco?: Endereco
  pensao?: Pensao
  newEndereco?: any
  tipoComprovanteEndereco?: any[]
  tipoComprovante?: any
  tipoRelacionamento?: any[]
  tipoDocumento?: any[]
  tipoRenda?: any[]
  anexosDocumento?: any[]
  anexosRenda?: any[]
  respostas?: any
  apiStates: ApiStates<typeof customActions>,
  recurso?: Recurso
  arquivo?: Arquivo
}

let initialState: IAlunoState = {
  apiStates: {},
}

const alunoSlicer = createSlice({
  name: 'alunoSlicer',
  initialState,
  reducers: {
    setApiState: (state, action: PayloadAction<ApiStates<typeof customActions>>) => {
      state.apiStates = { ...state.apiStates, ...action.payload }
    },
    setAluno: (state, action: PayloadAction<Aluno>) => {
      state.aluno = action.payload
    },
    setMembro: (state, action: PayloadAction<MembroFamiliar[]>) => {
      state.membros = action.payload
    },
    setArquivo: (state, action: PayloadAction<Arquivo>) => {
      state.arquivo = action.payload
    },
    setTipoRelacionamento: (state, action: PayloadAction<any[]>) => {
      state.tipoRelacionamento = action.payload
    },
    setTipoDocumento: (state, action: PayloadAction<any[]>) => {
      state.tipoDocumento = action.payload
    },
    setTipoRenda: (state, action: PayloadAction<any[]>) => {
      state.tipoRenda = action.payload
    },
    setTipoComprovante: (state, action: PayloadAction<any[]>) => {
      state.tipoComprovante = action.payload
    },
    setTipoComprovanteEndereco: (state, action: PayloadAction<any[]>) => {
      state.tipoComprovanteEndereco = action.payload
    },
    setEndereco: (state, action: PayloadAction<Endereco>) => {
      state.endereco = action.payload
    },
    setNewEndereco: (state, action: PayloadAction<any>) => {
      state.newEndereco = action.payload
    },
    setAnexosDocumento: (state, action: PayloadAction<any>) => {
      state.anexosDocumento = action.payload
    },
    setAnexosRenda: (state, action: PayloadAction<any>) => {
      state.anexosRenda = action.payload
    },
    setRespostas: (state, action: PayloadAction<any>) => {
      state.respostas = action.payload
    },
    setPensao: (state, action: PayloadAction<Pensao>) => {
      state.pensao = action.payload
    },
    setGabarito: (state, action: PayloadAction<any>) => {
      state.gabarito = action.payload
    },
    setAnaliseGabarito: (state, action: PayloadAction<any>) => {
      state.analiseGabarito = action.payload
    },
    setRecurso: (state, action: PayloadAction<any>) => {
      state.recurso = action.payload
    },
  },
})

const customActions = {
  analiseGabaritoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ analiseGabaritoAction: { state: apiState.loading } }))

        const result = await apiInstance.get<any>('aluno/analisegabarito')

        dispatch(alunoSlicer.actions.setAnaliseGabarito(result.data))

        dispatch(alunoSlicer.actions.setApiState({ analiseGabaritoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ analiseGabaritoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  finalizarComplementacaoAction: (finalizado: boolean) => {
    let info = {
      finalizadoComplementacao: finalizado
    }
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ finalizarComplementacaoAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/finalizarcomplementacao', info)

        dispatch(alunoSlicer.actions.setApiState({ finalizarComplementacaoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ finalizarComplementacaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  finalizarProcessoAction: (finalizado: boolean) => {
    let info = {
      finalizado: finalizado
    }
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ finalizarProcessoAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/finalizar', info)

        dispatch(alunoSlicer.actions.setApiState({ finalizarProcessoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ finalizarProcessoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteAnexoPISAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoPISAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/pis?pisid=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteAnexoPISAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoPISAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarPISAction: (pis: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarPISAction: { state: apiState.loading } }))

        const form = new FormData()

        pis.arquivo && form.append('arquivo', pis.arquivo)
        pis.numero && form.append('numero', pis.numero)
        form.append('pessoaId', pis.pessoaId.toString())
        
        const result = await apiInstance.post('aluno/pis', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarIRAction: { state: getStateByStatus(result.status) } }))
      } 
      catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarPISAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteAnexoExtratoAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoExtratoAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/extratobancario?ExtratoBancarioId=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteAnexoExtratoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoExtratoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarExtratoAction: (extrato: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarExtratoAction: { state: apiState.loading } }))

        const form = new FormData()

        extrato.arquivo && form.append('arquivo', extrato.arquivo)
        form.append('pessoaId', extrato.pessoaId.toString())
        
        const result = await apiInstance.post('aluno/extratobancario', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarExtratoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarExtratoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarObservacaoAction: (observacao: string) => {
    let info = {
      observacao: observacao
    }
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarObservacaoAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/observacao', info)

        if(result.status === 200) toast.success("Observação enviada com sucesso!");

        dispatch(alunoSlicer.actions.setApiState({ cadastrarObservacaoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarObservacaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteAnexoCTPSAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoCTPSAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/carteiradetrabalho?ctpsid=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteAnexoCTPSAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoCTPSAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarCTPSAction: (ctps: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarCTPSAction: { state: apiState.loading } }))

        const form = new FormData()

        ctps.arquivo && form.append('arquivo', ctps.arquivo)
        ctps.numero && form.append('numero', ctps.numero)
        form.append('pessoaId', ctps.pessoaId.toString())
        
        const result = await apiInstance.post('aluno/carteiradetrabalho', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarCTPSAction: { state: getStateByStatus(result.status) } }))
      } 
      catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarCTPSAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteAnexoIRAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoIRAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/impostoderenda?ImpostoDeRendaId=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteAnexoIRAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteAnexoIRAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarIRAction: (ir: any) => {
    return async (dispatch: any) => {
        try {
          dispatch(alunoSlicer.actions.setApiState({ cadastrarIRAction: { state: apiState.loading } }))
  
          const form = new FormData()
  
          ir.arquivo && form.append('arquivo', ir.arquivo)
          form.append('pessoaId', ir.pessoaId.toString())
          
          const result = await apiInstance.post('aluno/impostoderenda', form, {headers: {
            'Content-Type': 'multipart/form-data',
          }})
  
          dispatch(alunoSlicer.actions.setApiState({ cadastrarIRAction: { state: getStateByStatus(result.status) } }))
        } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarIRAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarPensaoAction: (pensao: Pensao | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarPensaoAction: { state: apiState.loading } }))

        const form = new FormData()

        if(pensao?.anexos){
          for (var i = 0; i < pensao.anexos.length; i++) {
            form.append('arquivos', pensao.anexos[i])
          }
        }
        pensao?.horaExtra && form.append('horaExtra', pensao.horaExtra.toString())
        pensao?.valor && form.append('valor', pensao.valor.toString())
        
        const result = await apiInstance.post('aluno/pensaoalimenticia', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarPensaoAction: { state: getStateByStatus(result.status) } }))
      }
      catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarPensaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  alterarTelefoneAction: (telefone: string) => {
    let info = {
      telefone: telefone
    }
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ alterarTelefoneAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/telefone', info)

        dispatch(alunoSlicer.actions.setApiState({ alterarTelefoneAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ alterarTelefoneAction: { state: apiState.error, error: error } }))
      }
    }
  },
  gabaritoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({gabaritoAction: { state: apiState.loading } }))

        let result = await apiInstance.get<any>('aluno/gabarito')

        dispatch(alunoSlicer.actions.setGabarito(result.data))

        dispatch(alunoSlicer.actions.setApiState({gabaritoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({gabaritoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  pensaoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({pensaoAction: { state: apiState.loading } }))

        let result = await apiInstance.get<Pensao>('aluno/pensaoalimenticia')

        dispatch(alunoSlicer.actions.setPensao(result.data))

        dispatch(alunoSlicer.actions.setApiState({pensaoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({pensaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deletePensaoAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deletePensaoAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/pensaoalimenticia/${id}`)

        dispatch(alunoSlicer.actions.setApiState({deletePensaoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deletePensaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteComprovantePensaoAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovantePensaoAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/pensaoalimenticia/anexo/${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteComprovantePensaoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovantePensaoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteComprovanteEnderecoAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteEnderecoAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/endereco?EnderecoId=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteEnderecoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteEnderecoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteComprovanteRespostaAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteRespostaAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/resposta?RespostaId=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteRespostaAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteComprovanteRespostaAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteDocumentoAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteDocumentoAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/documento?DocumentoId=${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteDocumentoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteDocumentoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteRendaAction: (id: string) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteRendaAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/renda/${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteRendaAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteRendaAction: { state: apiState.error, error: error } }))
      }
    }
  },
  deleteMembroAction: (id: string | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({deleteMembroAction: { state: apiState.loading } }))

        let result = await apiInstance.delete<any>(`aluno/membro/${id}`)

        dispatch(alunoSlicer.actions.setApiState({deleteMembroAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({deleteMembroAction: { state: apiState.error, error: error } }))
      }
    }
  },
  respostasAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({respostasAction: { state: apiState.loading } }))

        let result = await apiInstance.get<any>('aluno/resposta')

        dispatch(alunoSlicer.actions.setRespostas(result.data))

        dispatch(alunoSlicer.actions.setApiState({respostasAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({respostasAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarRespostasAction: (resposta: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarRespostasAction: { state: apiState.loading } }))

        const form = new FormData()

        resposta.arquivo && form.append('arquivo', resposta.arquivo)
        resposta.opcaoId && form.append('opcaoId', resposta.opcaoId)
        resposta.questaoId && form.append('questaoId', resposta.questaoId)
        
        const result = await apiInstance.post('aluno/resposta', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarRespostasAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarRespostasAction: { state: apiState.error, error: error } }))
      }
    }
  },
  adicionarRecursoAction: (description: string, document: File) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ adicionarRecursoAction: { state: apiState.loading } }))

        const form = new FormData()

        form.append('descricao', description)
        form.append('arquivo', document)

        const result = await apiInstance.post('aluno/recurso', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        if(result.status === 200) toast.success("Recurso adicionado com sucesso!");

        dispatch(alunoSlicer.actions.setApiState({ adicionarRecursoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        toast.error(error.response.data.message);
        dispatch(alunoSlicer.actions.setApiState({ adicionarRecursoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  listarRecursoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ listarRecursoAction: { state: apiState.loading } }))

        const result = await apiInstance.get('aluno/recurso')

        dispatch(alunoSlicer.actions.setRecurso(result.data))

        dispatch(alunoSlicer.actions.setApiState({ listarRecursoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ listarRecursoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  tiposAction: (tipo: string) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ tiposAction: { state: apiState.loading } }))

        const result = await apiInstance.get<any[]>(`tipo/${tipo}`)

        switch(tipo)
        {
          case 'tipocomprovanteendereco':
            dispatch(alunoSlicer.actions.setTipoComprovanteEndereco(result.data))
          break

          case 'tiporelacao':
            dispatch(alunoSlicer.actions.setTipoRelacionamento(result.data))
          break

          case 'tiporenda':
            dispatch(alunoSlicer.actions.setTipoRenda(result.data))
          break

          case 'tipodocumento':
            dispatch(alunoSlicer.actions.setTipoDocumento(result.data))
          break

          case 'TipoComprovanteRenda':
            dispatch(alunoSlicer.actions.setTipoComprovante(result.data))
          break
        }

        dispatch(alunoSlicer.actions.setApiState({ tiposAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ tiposAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarDocumentoAction: (documento: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarDocumentoAction: { state: apiState.loading } }))

        const form = new FormData()

        documento.anexo && form.append('arquivo', documento.anexo)
        documento.numero && form.append('numero', documento.numero)
        form.append('pessoaId', documento.pessoaId.toString())
        documento.idDocumento && form.append('idDocumento', documento.idDocumento.toString())
        documento.tipoDocumentoId && form.append('tipoDocumentoId', documento.tipoDocumentoId.toString())
        
        const result = await apiInstance.post('aluno/documento', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ cadastrarDocumentoAction: { state: getStateByStatus(result.status) } }))
      } 
      catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarDocumentoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarRendaAction: (renda: any) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarRendaAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/renda', renda)

        dispatch(alunoSlicer.actions.setApiState({ cadastrarRendaAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarRendaAction: { state: apiState.error, error: error } }))
      }
    }
  },
  anexosAction: (idDocumento: string | null | undefined, idRenda: string | null | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({anexosAction: { state: apiState.loading } }))

        let resultDocumentos = await apiInstance.get<any>(`anexo?DocumentoId=${idDocumento}`)
        dispatch(alunoSlicer.actions.setAnexosDocumento(resultDocumentos.data))

        let resultRenda = await apiInstance.get<any>(`anexo?RendaId=${idRenda}`)
        dispatch(alunoSlicer.actions.setAnexosRenda(resultRenda.data))

        dispatch(alunoSlicer.actions.setApiState({anexosAction: { state: getStateByStatus(resultRenda.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({anexosAction: { state: apiState.error, error: error } }))
      }
    }
  },
  cadastrarMembroAction: (membro: MembroFamiliar | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarMembroAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/membro', membro)

        dispatch(alunoSlicer.actions.setApiState({ cadastrarMembroAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ cadastrarMembroAction: { state: apiState.error, error: error } }))
      }
    }
  },
  consultaCepAction: (cep: string) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ consultaCepAction: { state: apiState.loading } }))

        const result = await apiInstance.get<any>(`https://viacep.com.br/ws/${cep}/json/`)

        dispatch(alunoSlicer.actions.setNewEndereco(result.data))

        dispatch(alunoSlicer.actions.setApiState({ consultaCepAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ consultaCepAction: { state: apiState.error, error: error } }))
      }
    }
  },
  aceitarTermoAction: () => {
    let info = {
      termo: true
    }
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ aceitarTermoAction: { state: apiState.loading } }))

        const result = await apiInstance.post('aluno/termo', info)

        dispatch(alunoSlicer.actions.setApiState({ aceitarTermoAction: { state: getStateByStatus(result.status) } }))
        window.location.reload();
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ aceitarTermoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  alterarEnderecoAction: (endereco: any | null | undefined) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ alterarEnderecoAction: { state: apiState.loading } }))

        const form = new FormData()

        endereco.cep && form.append('cep', endereco.cep)
        endereco.uf && form.append('uf', endereco.uf)
        endereco.municipio && form.append('municipio', endereco.municipio)
        endereco.bairro && form.append('bairro', endereco.bairro)
        endereco.logradouro && form.append('logradouro', endereco.logradouro)
        endereco.numero && form.append('numero', endereco.numero)
        endereco.complemento && form.append('complemento', endereco.complemento)
        endereco.anexo && form.append('arquivo', endereco.anexo)
        endereco.tipoComprovanteEnderecoId && form.append('tipoComprovanteEnderecoId', endereco.tipoComprovanteEnderecoId.toString())
        
        const result = await apiInstance.post('aluno/endereco', form, {headers: {
          'Content-Type': 'multipart/form-data',
        }})

        dispatch(alunoSlicer.actions.setApiState({ alterarEnderecoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ alterarEnderecoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  membrosAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ membrosAction: { state: apiState.loading } }))

        const result = await apiInstance.get<MembroFamiliar[]>('aluno/membro')

        dispatch(alunoSlicer.actions.setMembro(result.data))

        dispatch(alunoSlicer.actions.setApiState({ membrosAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ membrosAction: { state: apiState.error, error: error } }))
      }
    }
  },
  retornarArquivoAction: (
    documentoId: string | undefined, 
    tipoDocumentoId: string | undefined,
    anexoId?: string | undefined
    ) => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ retornarArquivoAction: { state: apiState.loading } }))

        const result = await apiInstance.get<Arquivo>('aluno/arquivo', { params: {
          documentoId, tipoDocumentoId, anexoId
        }})

        dispatch(alunoSlicer.actions.setArquivo(result.data))

        dispatch(alunoSlicer.actions.setApiState({ retornarArquivoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ retornarArquivoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  alunoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ alunoAction: { state: apiState.loading } }))

        const result = await apiInstance.get<Aluno>('aluno')

        dispatch(alunoSlicer.actions.setAluno(result.data))

        dispatch(alunoSlicer.actions.setApiState({ alunoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ alunoAction: { state: apiState.error, error: error } }))
      }
    }
  },
  enderecoAction: () => {
    return async (dispatch: any) => {
      try {
        dispatch(alunoSlicer.actions.setApiState({ enderecoAction: { state: apiState.loading } }))

        const result = await apiInstance.get<Endereco>('aluno/endereco')

        dispatch(alunoSlicer.actions.setEndereco(result.data))

        dispatch(alunoSlicer.actions.setApiState({ enderecoAction: { state: getStateByStatus(result.status) } }))
      } catch (error: any) {
        dispatch(alunoSlicer.actions.setApiState({ enderecoAction: { state: apiState.error, error: error } }))
      }
    }
  },
}

const AlunoReducer = alunoSlicer.reducer
const AlunoActions = { ...customActions }

export { AlunoReducer, AlunoActions }
