import React from 'react'
import styled from 'styled-components'
import { Button, IProps } from './Button'

const StyledTextButton = styled(Button)<{ color: string; lightenDarkenColor: (...args: any[]) => any }>`
  background-color: transparent;
  color: ${(props) => props.theme[props.color]};

  box-shadow: none;

  &:hover {
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], 0.9)};
    box-shadow: none;
  }

  &:focus {
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], 0.8)};
    outline: 0;
    box-shadow: none;
  }

  &:active {
    background-color: ${(props) => props.lightenDarkenColor(props.theme[props.color], 0.6)};
  }
`

/**
 * Button
 */
/** @component */
export const TextButton = ({ color = 'primaryColor', isLoading = false, ...props }: Partial<IProps>) => {
  const LightenDarkenColor = (c: string = '#3F51B5', percent: number) => {
    const f = parseInt(c.slice(1), 16)
    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = f >> 16
    const G = (f >> 8) & 0x00ff
    const B = f & 0x0000ff
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    )
  }

  return <StyledTextButton lightenDarkenColor={LightenDarkenColor} color={color} isLoading={isLoading} {...props} />
}
